import { useContext, useMemo, useState } from 'react';

import { LocationUtils } from '../../../../../../../utilities';
import { APIContext } from '../../../../../../../services/api/Api.context';

export const useBrokerOffices = () => {
	const {
		brokerContactApi: { useContactLocationSearch },
	} = useContext(APIContext);
	const [search, setSearch] = useState('');
	const {
		data,
		query: { isLoading },
	} = useContactLocationSearch({ search, page: 0 });
	const options = useMemo(() => data?.map(LocationUtils.parseLocation) ?? [], [data]);

	return { options, onSearch: setSearch, isLoading };
};
