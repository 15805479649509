import { useParams } from 'react-router-dom';
import { useCallback, useContext } from 'react';

import { useContactsListStore } from '../../../../../ContactsList.store';
import { ContactsListContext } from '../../../../../ContactsList.context';
import { ExportContext } from '../../../../../../../../context/ExportContext';
import { BrokerContactFilters, EmployerContactFilters } from 'common.model/src/types/common/search/filters/ContactFilters';
import { getApiPayloadFromState } from '../../../../../../Search/helpers/getApiPayloadFromState';

export const useExportSelected = () => {
	const { listId } = useParams();
	const { checkedMap, tab, checkMode } = useContactsListStore();
	const { filters } = useContext(ContactsListContext);
	const { isExporting, onExport } = useContext(ExportContext);
	const exportSelected = useCallback(() => {
		if (tab === 'broker') {
			onExport({
				params: {
					...getApiPayloadFromState({ checkedMap, checkMode }),
					filters: filters as BrokerContactFilters,
					listId,
					isSelecteAll: checkMode === 'select-all',
				},
				type: 'brokerContactsList',
			});
		}
		if (tab === 'employer') {
			onExport({
				params: {
					...getApiPayloadFromState({ checkedMap, checkMode }),
					filters: filters as EmployerContactFilters,
					listId,
					isSelecteAll: checkMode === 'select-all',
				},
				type: 'employerContactsList',
			});
		}
	}, [tab, onExport, filters, listId, checkedMap, checkMode]);

	return { exportSelected, isExporting };
};
