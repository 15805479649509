import { useCallback, useContext, useMemo } from 'react';

import { ContactDTO } from '../../../../../../../queries';
import { ContactUtils } from '../../../../../../../utilities';
import { useEmployerContactsStore } from '../../../EmployerContacts.store';
import { APIContext } from '../../../../../../../services/api/Api.context';
import { EmployerContactCardV2 } from '../../../../EmployerContactCardV2/EmployerContactCardV2';
import { useOnPurchaseContactSuccess } from '../../../../../../../hooks/useOnPurchaseContactSuccess';

export const Row = ({ contact }: { contact: ContactDTO }) => {
	const { toggleCheckMap, checkedMap, addPurchasedContact } = useEmployerContactsStore();
	const purchasable = useMemo(() => ContactUtils.isContactPurchasable(contact), [contact]);
	const purchased = useMemo(() => ContactUtils.isContactPurchased(contact), [contact]);
	const toggle = useCallback(() => toggleCheckMap(contact.id), [toggleCheckMap, contact]);
	const checked = useMemo(() => checkedMap[contact.id], [checkedMap, contact]);
	const onSuccess = useOnPurchaseContactSuccess({
		callback: (data) => {
			addPurchasedContact({
				id: data?.res?.profile_url,
				email: data?.res?.work_email,
				phone: data?.res?.mobile_phone_1,
			});
		},
	});
	const { brokerContactApi } = useContext(APIContext);
	const { mutate, loading: gettingContact } = brokerContactApi.useGetContact(
		{ profile_url: contact?.linkedinUrl },
		{
			onSuccess,
		},
	);

	return (
		<EmployerContactCardV2
			{...contact}
			variant="search"
			checked={checked}
			gettingContact={gettingContact}
			loading={false}
			onEmployerClick={() => window.open(`${window.location.origin}/Employer/` + contact.brokerId)}
			onGetContact={mutate}
			purchasable={purchasable}
			purchased={purchased}
			toggle={toggle}
		/>
	);
};
