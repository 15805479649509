import { useMemo, useState } from 'react';
import { AutoComplete, Box, Collapsible, InputLabel, Tag } from '@benefitflow/designsystem';

import { useIsOpen, useLoading, useOnDelete, useOnSearch, useOnSelect, useOptions, useToggle, useValues } from './hooks';

export const Location = () => {
	const [search, setSearch] = useState('');
	const values = useValues();
	const onDelete = useOnDelete();
	const loading = useLoading();
	const onSelect = useOnSelect();
	const options = useOptions({ search });
	const onSearch = useOnSearch({ setSearch });
	const selectedValues = useMemo(() => values.map(({ value }) => value), [values]);
	const isOpen = useIsOpen();
	const toggle = useToggle();

	return (
		<Collapsible
			headerTestId="location-filter-header"
			isOpen={isOpen}
			toggle={toggle}
			title="Location"
			icon="location"
			content={
				values.length && (
					<Box display="flex" gap="xxs" flexWrap="wrap">
						{values.map(({ label, value }) => (
							<Tag label={label} onDelete={onDelete(value)} />
						))}
					</Box>
				)
			}
			openContent={
				<InputLabel label="Service Providers located in [California]">
					<AutoComplete
						inputTestId="hq-location-filter-input"
						selectedValues={selectedValues}
						loading={loading}
						placeholder="e.g. California or Los Angeles"
						onSelect={onSelect}
						options={options}
						onSearch={onSearch}
					/>
				</InputLabel>
			}
		/>
	);
};
