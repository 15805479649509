import { createContext } from 'react';

import { ContactsListContext as ContactsListContextType } from './ContactsList.types';
import { ListType } from 'common.model/src/db/model-decorators/type-extensions';

export const ContactsListContext = createContext<ContactsListContextType>({
	contacts: [],
	listType: ListType.BROKER_CONTACT,
	fetchNextPage: () => {},
	hasNextPage: false,
	isFetching: false,
	isLoading: false,
	filters: {} as ContactsListContextType['filters'],
	totalNumberOfContacts: 0,
	numberOfSelectedContacts: 0,
	isLoadingFilters: false,
} satisfies ContactsListContextType);
