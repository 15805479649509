import { useNavigate, useSearchParams } from 'react-router-dom';
import { BrokerOffice } from 'common.services/src/search/BrokerElasticSearchCache';

import { useEmployers } from './hooks';
import { Table, TextButton, Typography } from '@benefitflow/designsystem';

export const EmployersList = ({ location }: { location: BrokerOffice['location'] }) => {
	const { data, isLoading } = useEmployers({ location });
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const tableProps = Table.use({
		maxHeight: 400,
		getRowProps: ({ id }) => ({ id }),
		columns: [
			{
				title: 'Employer Name',
				whiteSpace: 'normal',
				key: 'name',
				render: ({ name, id }) => <TextButton onClick={() => navigate(`/Employer/${id}`)}>{name}</TextButton>,
				cellAlign: 'left',
				headerAlign: 'left',
			},
			{
				title: 'Employer Location',
				whiteSpace: 'normal',
				cellAlign: 'left',
				headerAlign: 'left',
				key: 'location',
			},
			{
				title: 'Funding',
				whiteSpace: 'normal',
				cellAlign: 'left',
				headerAlign: 'left',
				key: 'funding',
			},
			{
				title: 'Primary Broker',
				whiteSpace: 'normal',
				key: 'primaryBroker',
				cellAlign: 'left',
				headerAlign: 'left',
				render: ({ primaryBroker, primaryBrokerId }) => {
					const currentBrokerId = searchParams.get('brokerId');
					if (currentBrokerId === primaryBrokerId) return <Typography.Text size="sm">{primaryBroker}</Typography.Text>;

					return (
						<TextButton
							onClick={() => {
								navigate(`/Broker?brokerId=${primaryBrokerId}`);
							}}
						>
							{primaryBroker}
						</TextButton>
					);
				},
			},
			{
				title: 'Renewal Date',
				whiteSpace: 'normal',
				key: 'renewalDate',
				cellAlign: 'left',
				headerAlign: 'left',
			},
			{
				title: 'Carrier Premiums',
				whiteSpace: 'normal',
				key: 'carrierPremiums',
				cellAlign: 'left',
				headerAlign: 'left',
			},
			{
				title: 'Total Employees',
				whiteSpace: 'normal',
				key: 'totalEmployees',
				cellAlign: 'right',
				headerAlign: 'right',
			},
		],
		data: data ?? [],
		loading: isLoading,
		skeleton: () => <Table.Skeleton numberOfRows={7} columns={new Array(7).fill(null).map(() => ({ width: 'full', height: 'md' }))} />,
	});

	return <Table {...tableProps} />;
};
