import { useEffect, useState } from 'react';

export const useElementDimensions = ({ ref, dependency }: { ref: React.MutableRefObject<HTMLDivElement>; dependency: any }) => {
	const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
	useEffect(() => {
		if (ref?.current) setDimensions({ width: ref.current.offsetWidth, height: ref.current.offsetHeight });
	}, [ref, dependency]);

	return dimensions;
};
