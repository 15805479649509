import { Box } from '@benefitflow/designsystem';

import { TabProps } from './TabLayout.types';
import { useGetValueByScreenSizes } from '../../../hooks/useGetValueByScreenSizes';

export const TabLayout = ({ filters, list, search = <></>, buttons, footer = <></>, sidebarRef }: TabProps) => {
	const getValueByScreenSizes = useGetValueByScreenSizes();

	return (
		<Box mt="lg" display="flex" gap="lg" m="sm" flexDirection={getValueByScreenSizes({ lg: 'row', md: 'row', sm: 'column' })}>
			<Box
				containerRef={sidebarRef}
				style={{
					width: getValueByScreenSizes({ lg: '30%', md: '30%', sm: '100%' }),
					maxWidth: getValueByScreenSizes({ sm: '100%', md: '36rem', lg: '36rem' }),
				}}
			>
				{filters}
			</Box>
			<Box display="flex" flexDirection="column" gap="sm" style={{ width: getValueByScreenSizes({ lg: '100%', md: '100%', sm: '100%' }) }}>
				{search}
				{buttons}
				{list}
				{footer}
			</Box>
		</Box>
	);
};
