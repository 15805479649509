import { FC, useState } from 'react';
import { ListTableHeader } from './ListTableHeader';
import { ListRowSet } from './ListRowSet';
import { useGetList } from '../../../../hooks/lists/useGetList';
import cn from 'classnames';
import { DeleteListModal } from 'pages/ListsPage/molecules/DeleteListModal';
import { useDisclosure } from 'new-beginning/hooks/useDisclosure';
import { useDeleteList } from '../../../../hooks/lists/useDeleteList';
import { ListType } from 'common.model/src/db/model-decorators/type-extensions';

interface ListTableProps {
	isSalesforceVisible: boolean;
	listType: ListType;
}

export const ListTable: FC<ListTableProps> = ({ isSalesforceVisible, listType }) => {
	const { isOpen, onClose, onOpen } = useDisclosure();
	const [listToDelete, setListToDelete] = useState<number | null>(null);
	const { onDelete, isDeleting } = useDeleteList({ listId: listToDelete });

	const { data, isLoading, handleSorting, currentSorting } = useGetList(listType);
	const handleSelectDelete = (listId: number) => {
		setListToDelete(listId);
		onOpen();
	};

	const handleDelete = () => {
		onDelete();
		onClose();
	};

	return (
		<>
			<div className={cn('bf-card-container', 'my-2', 'bg-white', 'rounded', 'mx-2')}>
				<div className={cn('bf-table-container', 'mb-0', 'rounded')}>
					<table className={cn('table', 'bf-table', 'mb-0', 'rounded')}>
						<ListTableHeader paginationParams={currentSorting} setSortConfig={handleSorting} />
						<ListRowSet
							isLoading={isLoading || isDeleting}
							data={data}
							isSalesforceVisible={isSalesforceVisible}
							handleDelete={handleSelectDelete}
						/>
					</table>
				</div>
			</div>
			<DeleteListModal
				isOpen={isOpen}
				onDelete={() => handleDelete()}
				onClose={onClose}
				list={data?.find((list) => list.id === listToDelete)}
			/>
		</>
	);
};
