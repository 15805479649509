import { useContext } from 'react';
import { InfiniteData, useInfiniteQuery, UseInfiniteQueryOptions } from '@tanstack/react-query';

import { QueryPath } from '../constants/path';
import { APIContext } from '../services/api/Api.context';
import { BrokerFilters } from 'common.model/src/types/common/search/filters/CompanyFilters';
import FormattingUtils from '../../ui-components/utils/FormattingUtils';
import { SearchBrokersResult } from 'common.model/src/types/services/broker-service/BrokerService';

export const useBrokersQuery = ({
	filters,
	options,
}: {
	filters: BrokerFilters;
	options?: UseInfiniteQueryOptions<SearchBrokersResult, unknown, Omit<SearchBrokersResult, 'data'> & { data: BrokerDTO[] }>;
}) => {
	const { brokerApi } = useContext(APIContext);
	const query = useInfiniteQuery<SearchBrokersResult, unknown, Omit<SearchBrokersResult, 'data'> & { data: BrokerDTO[] }>(
		[QueryPath.Brokers, filters],
		(params) => brokerApi.useSearch.bind(brokerApi)({ ...filters, page: params?.pageParam ?? 0 }),
		{
			...options,
			select: _select,
		},
	);

	return query;
};

export interface BrokerDTO {
	id?: string;
	imageSrcs?: string[];
	name?: string;
	location?: string;
	website?: string;
	totalContacts?: string;
	linkedinUrl?: string;

	commissionsValue?: string;
	premiumsValue?: string;
	numberOfClients?: string;
	numberOfOffices?: string;
}

export const _select = (data: InfiniteData<SearchBrokersResult>): InfiniteData<Omit<SearchBrokersResult, 'data'> & { data: BrokerDTO[] }> => {
	const pages = data.pages.map((page) => ({
		data: page.data.map((raw) => ({
			imageSrcs: (() => {
				const imageUrl = (() => {
					const match = raw?.linkedinProfileUrl?.match(/company\/([^/?]+)/);
					return match ? match[1] : '';
				})();
				const stage = import.meta.env.VITE_STAGE?.trim() === 'prod' ? 'prod' : 'qa'; // beside prod both for qa and local we will use qa
				return [`https://benefit-flow-data-broker-images-${stage}.s3.amazonaws.com/${imageUrl}.jpg`, `https://logo.clearbit.com/${raw.website}`];
			})(),
			linkedinUrl: raw.linkedinProfileUrl,
			commissionsValue: `$${FormattingUtils.formatNumberAbbrev(raw.commissions)}`,
			id: raw.brokerMasterId,
			location: raw.headquarters,
			name: FormattingUtils.formatUpperCasing(raw.masterBrokerName),
			numberOfClients: String(FormattingUtils.formatCommas(raw.clientCount)),
			numberOfOffices: FormattingUtils.formatCommas(raw.officeLocationCount),
			premiumsValue: `$${FormattingUtils.formatNumberAbbrev(raw.carrierPremiums)}`,
			totalContacts: String(FormattingUtils.formatCommas(raw.contactCount)),
			website: raw.website,
		})),
		page: page.page,
		next: null,
		previous: null,
	}));

	return { ...data, pages };
};
