import { useEffect, useState } from 'react';

import { IncludeExcludeFilterProps } from '../IncludeExcludeFilter';
import { FormattingUtils } from '../../../../../../../ui-components';

export const useFormattedOptions = ({ options, allOptionsLabel }: Pick<IncludeExcludeFilterProps, 'options' | 'allOptionsLabel'>) => {
	const [formattedOptions, setFormattedOptions] = useState([]);
	useEffect(() => {
		if (options?.length)
			setFormattedOptions(
				options.map(({ value, count }) => {
					if (value === '__all__')
						return {
							label: `${allOptionsLabel ?? 'Any'} (${FormattingUtils.formatNumberAbbrev(count)})`,
							value,
							data: {},
						};
					return {
						label: `${FormattingUtils.formatUpperCasing(value)} (${FormattingUtils.formatNumberAbbrev(count)})`,
						value: value,
						data: {},
					};
				}),
			);
	}, [options, setFormattedOptions, allOptionsLabel]);

	return formattedOptions;
};
