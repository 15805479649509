import { Typography, Box } from '@benefitflow/designsystem';

export const Section = ({ title }: { title: string }) => {
	return (
		<Box backgroundColor="neutral-200" p="sm" borderRadius="sm">
			<Typography.Text size="sm" color="neutral-500">
				{title}
			</Typography.Text>
		</Box>
	);
};
