import { useCallback, useContext } from 'react';

import { ExportContext } from '../../../../../../context/ExportContext';
import { ContactListHeaderContext } from '../../../ContactListHeader.context';
import { getApiPayloadFromState } from '../../../../../pages/Search/helpers/getApiPayloadFromState';

export const useOnClick = () => {
	const context = useContext(ContactListHeaderContext);
	const { onExport, isExporting } = useContext(ExportContext);

	const onClick = useCallback(() => {
		const payload = getApiPayloadFromState({ checkedMap: context.store.checkedMap, checkMode: context.store.checkMode });
		if (context.listId) {
			if (context.type === 'employer') {
				onExport({
					params: {
						listId: context.listId,
						filters: context.store.filters,
						isSelecteAll: context.store.checkMode === 'select-all',
						...payload,
					},
					type: 'employerContactsList',
				});
				return;
			}
			if (context.type === 'broker') {
				onExport({
					params: {
						listId: context.listId,
						filters: context.store.filters,
						isSelecteAll: context.store.checkMode === 'select-all',
						...payload,
					},
					type: 'brokerContactsList',
				});
				return;
			}
		}
		if (context.type === 'employer') {
			onExport({ ...payload, filters: context.store.filters, type: 'employerContacts' });
			return;
		}
		if (context.type === 'broker') {
			onExport({ params: { ...payload, filters: context.store.filters }, type: 'brokerContacts' });
			return;
		}
	}, [context, onExport]);

	return {
		onClick,
		loading: isExporting,
	};
};
