export enum TabsEnum {
	BROKERS = 'brokers',
	BROKER_CONTACTS = 'brokerContacts',
	EMPLOYERS = 'employers',
	EMPLOYER_CONTACTS = 'employerContacts',
	SERVICE_PROVIDERS = 'serviceProviders',
}

export interface SearchStore {
	tab: TabsEnum;
	setTab: (tab: TabsEnum) => void;
}
