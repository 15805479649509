import { useContext } from 'react';

import { APIContext } from '../../../../../services/api/Api.context';
import { useEmployersStore } from '../Employers.store';

export const useFilterOptions = () => {
	const { filters } = useEmployersStore();
	const {
		employerApi: { useSearchResultsBreakdown },
	} = useContext(APIContext);

	const {
		data,
		query: { isLoading },
	} = useSearchResultsBreakdown(filters);

	return {
		options: data,
		isLoading,
	};
};
