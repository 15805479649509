import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export const useOnClickBack = () => {
	const navigate = useNavigate();

	const onClickBack = useCallback(() => {
		navigate(-1);
	}, [navigate]);

	return onClickBack;
};
