import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useBrokerContactsDetailsStore } from '../Contacts.store';

export const useSetBrokerId = () => {
	const { setFilters } = useBrokerContactsDetailsStore();
	const [getSearchParams] = useSearchParams();
	const brokerId = getSearchParams.get('brokerId');

	useEffect(() => {
		if (brokerId) setFilters.companyMasterIds.set([brokerId]);
	}, [brokerId, setFilters]);
};
