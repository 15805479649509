import { Typography } from '@benefitflow/designsystem';
import { useState } from 'react';

export const NumberOfContacts = ({ onClick, value }: { value: string; onClick: () => void }) => {
	const [hovered, setHovered] = useState(false);

	if (!value) return null;
	return (
		<Typography.Text
			onMouseEnter={() => setHovered(true)}
			onMouseLeave={() => setHovered(false)}
			decoration={hovered ? 'underline' : undefined}
			onClick={onClick}
			style={{ cursor: 'pointer' }}
			weight="regular"
			color="primary-500"
		>
			{value} Contacts
		</Typography.Text>
	);
};
