import { useContext, useRef } from 'react';

import { List, Filters, Header } from './components';
import { SearchBar } from '../components';
import { TabLayout } from '../../../shared/TabLayout/TabLayout';
import { useNumberOfSelectedContacts } from '../hooks';
import { useContactsQuery } from '../../../../queries';
import { useClearCheckMapOnFilterChange } from './hooks';
import { useContacts } from '../../../../hooks/useContacts';
import { APIContext } from '../../../../services/api/Api.context';
import { useEmployerContactsStore } from './EmployerContacts.store';
import { EmployerContactsContext } from './EmployerContacts.context';
import { useElementDimensions } from '../../../../hooks/useElementDimensions';
import { ContactTypeEnum } from 'common.model/src/types/ContactTypeEnum';

export const EmployerContacts = () => {
	const sidebarRef = useRef(null);
	const store = useEmployerContactsStore();
	const { employerApi } = useContext(APIContext);
	const { data: employerContactsFiltersData, loading: filtersAreLoading } = employerApi.useSearchContactsBreakdown(store.filters);
	const { data, isLoading, isFetching, fetchNextPage, hasNextPage } = useContactsQuery({
		filters: store.filters,
		contactType: ContactTypeEnum.EMPLOYER,
		options: {
			getNextPageParam: (lastPage) => {
				if (!lastPage?.anonymizedContacts?.length) return null;
				return lastPage.page + 1;
			},
		},
	});
	const contacts = useContacts({ data, store });
	const numberOfSelectedContacts = useNumberOfSelectedContacts({
		checkedMap: store.checkedMap,
		checkMode: store.checkMode,
		totalNumberOfContacts: employerContactsFiltersData?.totalHits ?? 0,
	});
	const { height: minHeight } = useElementDimensions({ ref: sidebarRef, dependency: store.filters });
	useClearCheckMapOnFilterChange();

	return (
		<EmployerContactsContext.Provider
			value={{
				contacts,
				filterOptions: employerContactsFiltersData?.breakdowns,
				isLoading: isLoading || filtersAreLoading,
				isFetching,
				fetchNextPage,
				hasNextPage,
				numberOfSelectedContacts,
				totalNumberOfContacts: employerContactsFiltersData?.totalHits ?? 0,
			}}
		>
			<TabLayout
				sidebarRef={sidebarRef}
				buttons={<Header />}
				filters={<Filters />}
				list={<List minHeight={minHeight} />}
				search={<SearchBar placeholder="Search employer contacts" setValue={store.setFilters.nameSearch} value={store.filters.nameSearch} />}
			/>
		</EmployerContactsContext.Provider>
	);
};
