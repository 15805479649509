import { create } from 'zustand';

import { StoreUtils } from '../../../../utilities';
import { BrokerContactsStore } from './BrokerContacts.types';
import { getDefaultBrokerContactFilters } from 'common.model/src/types/common/search/filters/ContactFilters';
import { BrokerContactFilters } from 'common.model/src/types/common/search/filters/ContactFilters';

type IncludeExcludeFilters = Omit<BrokerContactsStore['filters'], 'yearsAtCompany' | 'search'>;

export const FILTERS = [
	'companyName' as const,
	'contactLocation' as const,
	'role' as const,
	'jobTitle' as const,
	'yearsAtCompany' as const,
	'licenseTypes' as const,
	'carrierAppointments' as const,
	'brokerOfficeLocations' as const,
	'brokerSize' as const,
];

export const useBrokerContactsStore = create<BrokerContactsStore>((set, get) => ({
	...StoreUtils.createContactSelectorMethods(set),
	...StoreUtils.createFilterVisibilityMethods(FILTERS, set),
	isAddToListDialogOpen: false,
	setIsAddToListDialogOpen: (isOpen: boolean) => set({ isAddToListDialogOpen: isOpen }),
	filters: getDefaultBrokerContactFilters(),
	resetFilters: () => set({ filters: getDefaultBrokerContactFilters() }),
	setFilter: {
		brokerSize: StoreUtils.createIncludeExcludeMethods<IncludeExcludeFilters, string>('brokerSize', set),
		carrierAppointments: StoreUtils.createIncludeExcludeMethods<IncludeExcludeFilters, string>('carrierAppointments', set),
		companyName: StoreUtils.createIncludeExcludeMethods<IncludeExcludeFilters, string>('companyName', set),
		contactLocation: StoreUtils.createLocationMethods('contactLocation', set),
		brokerOfficeLocations: StoreUtils.createLocationMethods('brokerOfficeLocations', set),
		jobTitles: StoreUtils.createIncludeExcludeMethods<IncludeExcludeFilters, string>('jobTitles', set),
		licenseTypes: StoreUtils.createIncludeExcludeMethods<IncludeExcludeFilters, string>('licenseTypes', set),
		roles: StoreUtils.createIncludeExcludeMethods<IncludeExcludeFilters, string>('roles', set),
		yearsAtCompany: StoreUtils.createRangeMethodsLegacy('yearsAtCompany', set),
		nameSearch: (value: string) =>
			set((state) => {
				const updated: BrokerContactFilters = {
					...state.filters,
					nameSearch: value, // this should probably be nameSearch
				};
				return { filters: updated };
			}),
	},
	setFilters: (filters) => set({ filters }),
	clearCheckedMap: () => set({ checkedMap: {}, checkMode: 'single-select' }),
	/**
	 * Used mainly for testing. Avoid using it in production code in favor of more specific methods.
	 */
	setState: (state) => set(state),
}));
