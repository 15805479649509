import { useLocation } from 'react-router-dom';

import { useBrokerSummaryQuery } from 'new-beginning/queries/useBrokerSummaryQuery/useBrokerSummaryQuery';
import { useBrokersStore } from '../../Search/Brokers/Brokers.store';

export const useSummary = () => {
	const { filters } = useBrokersStore();
	const location = useLocation();
	const { data, isLoading } = useBrokerSummaryQuery({
		...filters,
		brokerMasterId: [new URLSearchParams(location.search).get('brokerId')],
	});

	return { ...data, loading: isLoading };
};
