import { useContext } from 'react';

import { useEmployerContactsStore } from '../../EmployerContacts.store';
import { APIContext } from '../../../../../../services/api/Api.context';
import { EmployerContactsContext } from '../../EmployerContacts.context';
import { ContactListHeader } from '../../../../../shared/ContactListHeader/ContactListHeader';

export const Header = () => {
	const store = useEmployerContactsStore();
	const context = useContext(EmployerContactsContext);
	const { employerApi } = useContext(APIContext);

	return (
		<ContactListHeader context={context} store={store} type="employer">
			<ContactListHeader.GetSelectButton useMutation={employerApi.useGetBulkContact} />
			<ContactListHeader.ExportButton />
			<ContactListHeader.AddToListButton
				useAddToExistingListMutation={employerApi.useAddSelectedToExistingList}
				useAddToNewListMutation={employerApi.useAddSelectedToNewList}
			/>
		</ContactListHeader>
	);
};
