import { useContext, useEffect, useMemo, useState } from 'react';
import { Box, Button, Divider, Icon, InputLabel, Select, Typography } from '@benefitflow/designsystem';

import { useAddToList } from '../../../../hooks';
import { AddToListButtonProps } from '../../../../AddToListButton.types';
import { APIContext } from '../../../../../../../../../services/api/Api.context';
import { ContactListHeaderContext } from '../../../../../../ContactListHeader.context';
import { ListType } from 'common.model/src/db/model-decorators/type-extensions';

export const Add = ({
	setTab,
	setIsAddToListDialogOpen,
	useAddToExistingListMutation,
}: {
	useAddToExistingListMutation: AddToListButtonProps['useAddToExistingListMutation'];
	setTab: (tab: 'add' | 'create') => void;
	setIsAddToListDialogOpen: (isOpen: boolean) => void;
}) => {
	const {
		store: { checkMode, checkedMap, filters },
		context: { numberOfSelectedContacts },
		type,
	} = useContext(ContactListHeaderContext);
	const [selectedListId, setSelectedListId] = useState('');
	const { listsApi } = useContext(APIContext);
	const { data, loading: isLoadingListOptions } = listsApi.useGetAllListsForUser();
	const filteredLists = useMemo(() => {
		const targetListType = type === 'broker' ? ListType.BROKER_CONTACT : ListType.EMPLOYER_CONTACT;
		if (data?.length) {
			return data.filter(({ id, list_type }) => id !== -1 && list_type === targetListType);
		}
		return [];
	}, [data, type]);
	useEffect(() => {
		if (filteredLists && filteredLists.length && selectedListId === '') {
			setSelectedListId(String(filteredLists[0].id));
		}
	}, [filteredLists, selectedListId]);
	const { addToList, loading: isAddingToList } = useAddToList({
		useAddToExistingListMutation,
		checkedMap,
		contactType: type,
		checkMode,
		filters,
		setIsAddToListDialogOpen,
	});
	const haveAtLeastOneOption = useMemo(() => !filteredLists?.length || !isLoadingListOptions, [filteredLists, isLoadingListOptions]);
	const listLabel = useMemo(() => (type === 'broker' ? 'Broker Contact List' : 'Employer Contact List'), [type]);
	const saveLabel = useMemo(() => `Save ${numberOfSelectedContacts} Contact${numberOfSelectedContacts > 1 ? 's' : ''}`, [numberOfSelectedContacts]);
	return (
		<Box display="flex" flexDirection="column">
			<Box m="lg" display="flex" flexDirection="column" gap="md">
				<Typography.Text size="lg" color="neutral-700">
					{saveLabel}
				</Typography.Text>
				{haveAtLeastOneOption && (
					<InputLabel label={listLabel}>
						<Select value={selectedListId} onChange={(event) => setSelectedListId(event.target.value)}>
							{filteredLists?.map(({ id, name }) => <Select.Option key={id} label={name} value={id} />)}
						</Select>
					</InputLabel>
				)}
				<Box onClick={() => setTab('create')} cursor="pointer" display="flex" gap="xs" alignItems="center">
					<Icon color="primary-500" name="add" size="md" />
					<Typography.Text color="primary-500">Create List</Typography.Text>
				</Box>
			</Box>

			<Divider />
			<Box m="lg" display="flex" justifyContent="end" alignItems="center" gap="md">
				<Box cursor="pointer" onClick={() => setIsAddToListDialogOpen(false)}>
					<Typography.Text color="primary-500">Cancel</Typography.Text>
				</Box>
				<Box>
					<Button fetching={isAddingToList} onClick={() => addToList(selectedListId)}>
						Confirm
					</Button>
				</Box>
			</Box>
		</Box>
	);
};
