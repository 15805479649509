import { useContext } from 'react';
import { APIContext } from '../services/api/Api.context';
import { InfiniteData, useInfiniteQuery, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { EmployerFilters } from 'common.model/src/types/common/search/filters/CompanyFilters';
import { SearchEmployerResult } from 'common.model/src/types/services/employer-service/EmployerService';
import FormattingUtils from '../../ui-components/utils/FormattingUtils';

export interface EmployerDTO {
	id: string;
	name: string;
	location: string;
	description: string;
	imageUrls: string[];
	website: string;

	fundingType: string;
	numberOfEmployees: string;
	renewalDate: string;
	brokerId: string;
	brokerName: string;
	brokerLocation: string;

	peo: string;
	ein?: string;
}

export const useEmployersQuery = ({
	filters,
	options,
}: {
	filters: EmployerFilters;
	options?: UseInfiniteQueryOptions<SearchEmployerResult, unknown, Omit<SearchEmployerResult, 'data'> & { data: EmployerDTO[] }>;
}) => {
	const { employerApi } = useContext(APIContext);
	const query = useInfiniteQuery<SearchEmployerResult, unknown, Omit<SearchEmployerResult, 'data'> & { data: EmployerDTO[] }>(
		['employers', filters],
		async (params) => employerApi.useSearch({ ...filters, page: params.pageParam ?? 0 }),
		{
			...options,
			select: _select,
		},
	);

	return query;
};

export const _select = (data: InfiniteData<SearchEmployerResult>): InfiniteData<Omit<SearchEmployerResult, 'data'> & { data: EmployerDTO[] }> => {
	console.log(data);
	return {
		...data,
		pages: data.pages.map((page) => ({
			...page,
			data: page.data.map(
				(raw) =>
					({
						id: raw.employerMasterId,
						name: raw.employerName,
						description: raw.naicsDescription,
						website: raw.employerWebsite,
						imageUrls: (() => {
							const stage = import.meta.env.VITE_STAGE?.trim() === 'prod' ? 'prod' : 'qa';
							return [
								`https://benefit-flow-data-linkedin-logo-image-${stage}.s3.amazonaws.com/${raw.employerLogoPath}`,
								`https://logo.clearbit.com/${raw.employerWebsite}`,
							];
						})(),
						fundingType: raw.fundingType,
						location: (() => {
							if (raw.employerCity && raw.employerState) return `${raw.employerCity}, ${raw.employerState}`;
							if (raw.employerCity) return raw.employerCity;
							if (raw.employerState) return raw.employerState;
							return;
						})(),
						numberOfEmployees: `${FormattingUtils.formatNumberAbbrev(raw.totalEmployees)}`,
						renewalDate: raw.renewalDate && FormattingUtils.formatMM_DD_YYYYtoMM_DD(raw.renewalDate),
						brokerName: raw.primaryBrokerMasterId != 'ffffffffffffffffffffffffffffffff' ? raw.primaryBrokerName : undefined,
						brokerLocation: (() => {
							if (!raw.primaryBrokerCity || !raw.primaryBrokerState) return;
							return `${raw.primaryBrokerCity}, ${raw.primaryBrokerState}`;
						})(),
						brokerId: raw.primaryBrokerMasterId,
						peo: raw.employerPEOName,
						ein: raw.employerEin,
					}) satisfies EmployerDTO,
			),
		})),
	};
};
