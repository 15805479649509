import { BrokerContactFilters, EmployerContactFilters } from "common.model/src/types/common/search/filters/ContactFilters";
import { ApiBase, IApiResponse, IMutationResponse } from "../ApiBase";
import {
	CompanySearchRequest, CompanySearchResponse, CreateListItemsRequest, CreateListRequest, CreateListResponse,
	DeleteListItemsRequest, DeleteListRequest, DeleteTenantRequest, DeleteTenantResponse, GetDownloadItemsRequest,
	GetDownloadItemsResponse, GetListItemRequest, GetListItemsBreakdownRequest, GetListItemsForListRequest, HasAccessRequest,
	MoveListItemsRequest
} from "common.model/src/types/services/list-service/ListsService";
import { ListAttributesWithCounts } from "common.model/src/db/models/List";
import { ContactSearchResponse } from "common.model/src/types/common/contact/ContactSearchResponse";
import { GetContactResponse } from "common.model/src/types/common/contact/GetContactResponse";
import { BreakdownResponse } from "common.model/src/types/common/search/Breakdowns";
import { BulkGetContactResponse } from "common.model/src/types/common/contact/BulkGetContactResponse";
import { APIClient } from "../types";
import { MutationOptions, QueryObserverOptions, UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { BulkGetContactRequest } from 'common.model/src/types/common/contact/BulkGetContactRequest';

export interface IListApi {
	useDeleteTenant: (request: DeleteTenantRequest) => IMutationResponse<DeleteTenantResponse>;
	useCreateList: (request: CreateListRequest) => IMutationResponse<CreateListResponse>;
	useCreateListItems: (request: CreateListItemsRequest) => IMutationResponse<number>;
	useGetAllListsForUser: (options?: QueryObserverOptions<ListAttributesWithCounts[]>) => IApiResponse<ListAttributesWithCounts[]>;
	useGetListItemsForList: (request: GetListItemsForListRequest) => IApiResponse<ContactSearchResponse>;
	useGetListItem: (request: GetListItemRequest) => IMutationResponse<GetContactResponse>;
	useGetListItemsForListBreakdown: (request: GetListItemsBreakdownRequest) => IApiResponse<BreakdownResponse>;
	useMoveListItems: (request: MoveListItemsRequest, options?: MutationOptions<number>) => IMutationResponse<number>;
	useDeleteListItems: (request: DeleteListItemsRequest, options?: MutationOptions<number>) => IMutationResponse<number>;
	useDeleteList: (request: DeleteListRequest, options?: MutationOptions<void>) => IMutationResponse<void>;
	useGetDownloadItems: (request: GetDownloadItemsRequest) => IApiResponse<GetDownloadItemsResponse>;
	useRevealSelectedListItems: (options?: UseMutationOptions<BulkGetContactResponse, unknown, BulkGetContactRequest<BrokerContactFilters | EmployerContactFilters>>) => UseMutationResult<BulkGetContactResponse , unknown, BulkGetContactRequest<BrokerContactFilters | EmployerContactFilters>>;
	useHasAccess: (request: HasAccessRequest) => IApiResponse<boolean>;
	useCompanySearch: (request: CompanySearchRequest) => IApiResponse<CompanySearchResponse>;
}

export class ListApi extends ApiBase implements IListApi {
	constructor(readonly client: APIClient, loggedInUser: string) {
		super(client, loggedInUser, 'lists');
	}

	useDeleteTenant = (request: DeleteTenantRequest): IMutationResponse<DeleteTenantResponse> =>
		this.mutateAuthenticated('deleteTenant', request);

	useCreateList = (request: CreateListRequest): IMutationResponse<CreateListResponse> =>
		this.mutateAuthenticated('createList', request);

	useCreateListItems = (request: CreateListItemsRequest): IMutationResponse<number> =>
		this.mutateAuthenticated('createListItems', request);

	useGetAllListsForUser = (options?: QueryObserverOptions<ListAttributesWithCounts[]>): IApiResponse<ListAttributesWithCounts[]> =>
		this.callAuthenticated('getAllListsForUser', {}, options);
	
	useGetListItemsForList = (request: GetListItemsForListRequest): IApiResponse<ContactSearchResponse> =>
		this.callAuthenticated('getListItemsForList', request);
	
	useGetListItem =(request: GetListItemRequest): IMutationResponse<GetContactResponse> =>
		this.mutateAuthenticated('getListItem', request);

	useGetListItemsForListBreakdown = (request: GetListItemsBreakdownRequest): IApiResponse<BreakdownResponse> => 
		this.callAuthenticated('getListItemsForListBreakdown', request);
	
	useMoveListItems = (request: MoveListItemsRequest, options?: MutationOptions<number>): IMutationResponse<number> =>
		this.mutateAuthenticated('moveListItems', request, options);

	useDeleteListItems = (request: DeleteListItemsRequest, options?: MutationOptions<number>): IMutationResponse<number> =>
		this.mutateAuthenticated('deleteListItems', request, options);

	useDeleteList = (request: DeleteListRequest, options?: MutationOptions<void>): IMutationResponse<void> => 
		this.mutateAuthenticated('deleteList', request, options);
	
	useGetDownloadItems = (request: GetDownloadItemsRequest): IApiResponse<GetDownloadItemsResponse> =>
		this.callAuthenticated('getDownloadItems', request);

	useRevealSelectedListItems = (options?: UseMutationOptions<BulkGetContactResponse, unknown, BulkGetContactRequest<BrokerContactFilters | EmployerContactFilters>>): UseMutationResult<BulkGetContactResponse, unknown, BulkGetContactRequest<BrokerContactFilters | EmployerContactFilters>> =>
		this.mutate('revealSelectedListItems', options);

	useHasAccess = (request: HasAccessRequest): IApiResponse<boolean> =>
		this.callAuthenticated('hasAccess', request);

	useCompanySearch = (request: CompanySearchRequest): IApiResponse<CompanySearchResponse> =>
		this.callAuthenticated('companySearch', request);
}