import { useMemo } from 'react';

import { useScreenSize } from '../../../../../hooks/useScreenSize';

export const useHeight = () => {
	const screenSize = useScreenSize();
	const height = useMemo(() => {
		switch (screenSize) {
			case 'sm':
				return 260;
			case 'md':
				return 125;
			case 'lg':
				return 115;
			default:
				return 115;
		}
	}, [screenSize]);

	return height;
};
