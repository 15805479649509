import { useEffect, useMemo } from 'react';

import { useContactsQuery } from '../../../../../../queries';
import { useBrokerContactsDetailsStore } from '../Contacts.store';
import { useSearchParams } from 'react-router-dom';
import { ContactTypeEnum } from 'common.model/src/types/ContactTypeEnum';

export const useContacts = () => {
	const { filters, visibleContactsPurchasedDurringSession, toggleCheckMap, checkMode, checkedMap } = useBrokerContactsDetailsStore();
	const [searchParams] = useSearchParams();
	const { data, isLoading, isFetching, fetchNextPage, hasNextPage } = useContactsQuery({
		filters: { ...filters, companyMasterIds: [searchParams.get('brokerId')] },
		options: {
			getNextPageParam: (lastPage) => {
				if (!lastPage?.anonymizedContacts?.length) return null;
				return lastPage.page + 1;
			},
		},
		contactType: ContactTypeEnum.BROKER,
	});
	const contacts = useMemo(() => {
		return (
			data?.pages
				?.flatMap((page) => page.data)
				?.map((contact) => {
					const purchasedContact = visibleContactsPurchasedDurringSession?.[contact.id];
					if (purchasedContact)
						return {
							...contact,
							...purchasedContact,
						};
					return contact;
				}) ?? []
		);
	}, [data, visibleContactsPurchasedDurringSession]);
	useEffect(() => {
		if (checkMode === 'select-all')
			contacts.forEach((contact) => {
				if (checkedMap[contact.id] === undefined) toggleCheckMap(contact.id);
			});
	}, [checkMode, checkedMap, toggleCheckMap, contacts]);

	return {
		contacts,
		isLoading,
		isFetching,
		hasNextPage,
		fetchNextPage,
	};
};
