import { Box } from '@benefitflow/designsystem';

import { ExportButton, GroupBySelector } from './components';

export const Header = () => {
	return (
		<Box display="flex" justifyContent="end" gap="sm">
			<GroupBySelector />
			<ExportButton />
		</Box>
	);
};
