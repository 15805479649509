import { useCallback, useContext } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useAddNotification } from '@benefitflow/designsystem';
import { ContactTypeEnum } from 'common.model/src/types/ContactTypeEnum';

import { QueryKey } from '../../../../../../../constants/queryKey';
import { APIContext } from '../../../../../../../services/api/Api.context';
import { Notifications } from '../../../../../../../constants/notifications';
import { ContactListHeaderContext } from '../../../../ContactListHeader.types';
import { getApiPayloadFromState } from '../../../../../../pages/Search/helpers/getApiPayloadFromState';

export const useAddToList = ({
	checkMode,
	checkedMap,
	filters,
	setIsAddToListDialogOpen,
	contactType,
	useAddToExistingListMutation,
}: {
	useAddToExistingListMutation;
	setIsAddToListDialogOpen: (isOpen: boolean) => void;
	contactType: 'employer' | 'broker';
} & Pick<ContactListHeaderContext['store'], 'checkMode' | 'checkedMap' | 'filters'>) => {
	const addNotification = useAddNotification();
	const queryClient = useQueryClient();
	const { listsApi } = useContext(APIContext);
	const { data } = listsApi.useGetAllListsForUser();
	const onSuccess = useCallback(
		({ listId, totalAdded }) => {
			const name = data?.find((list) => list.id == listId).name;
			const notification = Notifications.Search.ByBrokers.Contacts.AddToListDialog.Success({ name, totalAdded });
			addNotification(notification);
			setIsAddToListDialogOpen(false);
			queryClient.invalidateQueries({ predicate: (query) => query.queryKey?.includes(QueryKey.Lists) });
		},
		[addNotification, data, setIsAddToListDialogOpen, queryClient],
	);
	const onError = useCallback(() => {
		addNotification(Notifications.Search.ByBrokers.Contacts.AddToListDialog.Error);
	}, [addNotification]);
	const { mutate, isLoading } = useAddToExistingListMutation({ onSuccess, onError });

	const addToList = useCallback(
		(listId: string) => {
			mutate({
				...getApiPayloadFromState({ checkedMap, checkMode }),
				filters: {
					...filters,
					contactType: (() => {
						if (contactType === 'employer') {
							return ContactTypeEnum.EMPLOYER;
						} else {
							return ContactTypeEnum.BROKER;
						}
					})(),
				},
				listId,
			});
		},
		[mutate, filters, checkedMap, contactType, checkMode],
	);

	return { addToList, loading: isLoading };
};
