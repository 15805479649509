import { useContext, useMemo } from 'react';
import { EntityLocation, LocationType } from 'common.model/src/types/master/location';
import { BrokerOffice } from 'common.services/src/search/BrokerElasticSearchCache';

import { useBrokersStore } from '../../../../../Brokers/Brokers.store';
import { APIContext } from '../../../../../../../../services/api/Api.context';
import FormattingUtils from '../../../../../../../../../ui-components/utils/FormattingUtils';
import { IBrokerClient } from 'common.model/src/types/services/broker-service/IBrokerClient';
import { useSearchParams } from 'react-router-dom';

export const useEmployers = ({ location }: { location: BrokerOffice['location'] }) => {
	const { filters } = useBrokersStore();
	const {
		brokerApi: { useGetBrokerEmployers },
	} = useContext(APIContext);
	const [searchParams] = useSearchParams();
	const payload = useMemo(
		() => ({
			...filters,
			brokerMasterId: [searchParams.get('brokerId')],
			brokerOfficeLocations: {
				include: [
					{
						location: {
							locationType: LocationType.CityState,
							city: location.city,
							state: location.longState,
							stateCode: location.state,
							searchValue: location.cityStateSearch,
						} satisfies EntityLocation,
						locationKey: `${location.city}, ${location.state}`,
						employerMasterId: null,
					},
				],
				exclude: [],
			},
		}),
		[filters, location, searchParams],
	);
	const { isLoading, data } = useGetBrokerEmployers<BrokerClientDTO[]>(payload, { select: _select });

	return { data, isLoading };
};

export interface BrokerClientDTO {
	id: string;
	name: string;
	location: string;
	funding: string;
	primaryBrokerId: string;
	primaryBroker: string;
	renewalDate: string;
	carrierPremiums: string;
	totalEmployees: string;
}

export const _select = (data: IBrokerClient[]): BrokerClientDTO[] =>
	data?.map((raw) => ({
		id: raw.employer_master_id,
		name: FormattingUtils.formatUpperCasing(raw.employer_master_name),
		location: `${FormattingUtils.formatUpperCasing(raw.employer_city)}, ${raw.employer_state}`,
		funding: raw.funding_type,
		primaryBrokerId: raw.primary_broker_unique_code,
		primaryBroker: FormattingUtils.formatUpperCasing(raw.primary_broker),
		renewalDate: FormattingUtils.formatMM_DD_YYYYtoMM_DD(raw.renewal_date),
		carrierPremiums: `$${FormattingUtils.formatNumberAbbrev(raw.total_premiums)}`,
		totalEmployees: `${FormattingUtils.formatNumberAbbrev(raw.total_employees)}`,
	})) ?? [];
