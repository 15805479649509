export const useEmployeeCount = () => {
	return {
		options: {
			start: Options,
			end: Options,
		},
	};
};

export type Count = 0 | 100 | 200 | 300 | 500 | 1000 | 2000 | 3000 | 5000 | 10000 | 3000000;

export const Options: { value: Count; label: string }[] = [
	{ value: 0, label: '0' },
	{ value: 100, label: '100' },
	{ value: 200, label: '200' },
	{ value: 300, label: '300' },
	{ value: 500, label: '500' },
	{ value: 1000, label: '1K' },
	{ value: 3000, label: '3K' },
	{ value: 5000, label: '5K' },
	{ value: 10000, label: '10K' },
	{ value: 3000000, label: '10K+' },
];

export type InputValue = `${Count}`;
