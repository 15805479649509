import { LocationType } from 'common.model/src/types/master/location';
import { FormattingUtils } from '../../../ui-components';

export const parseLocation = (data) => ({
	value: data.searchValue,
	label: (() => {
		if (data.locationType === LocationType.CityState)
			return data.city
				? `${FormattingUtils.formatUpperCasing(data.city)}, ${FormattingUtils.formatUpperCasing(data.state)} (${data.stateCode})`
				: `${FormattingUtils.formatUpperCasing(data.state)} (${data.stateCode})`;
		return `${data.zip} (${FormattingUtils.formatUpperCasing(data.city)}, ${data.stateCode})`;
	})(),
	data,
});
