import { useEffect } from 'react';

import { useBrokerContactsStore } from '../BrokerContacts.store';

export const useResetCheckedMapOnFiltersChange = () => {
	const { filters, setCheckMap } = useBrokerContactsStore();
	useEffect(() => {
		setCheckMap({});
	}, [setCheckMap, filters]);
};
