import { useMemo } from 'react';
import { Avatar, Box, Button, Description, Paper, Typography } from '@benefitflow/designsystem';

import { Labels } from '../BrokerCard/components';
import { EmployerCardProps } from './EmployerCard.types';
import { Card } from 'new-beginning/components/shared/Card/Card';
import { useGetValueByScreenSizes } from 'new-beginning/hooks/useGetValueByScreenSizes';
import { TextOverflowTooltip } from 'new-beginning/components/shared/TextOverflowTooltip';

export const EmployerCard = ({
	location,
	name,
	brokerName,
	imageUrls,
	website,
	loading,
	moreInfoDisabled,
	moreInfoCallback,
	description,
	brokerLocation,
	fundingType,
	numberOfEmployees,
	renewalDate,
	onClickBrokerLocation,
}: EmployerCardProps) => {
	const getValueByScreenSizes = useGetValueByScreenSizes();
	const brokerLabel = useMemo(() => {
		if (!brokerName) return;
		if (!brokerLocation) return brokerName;
		return `${brokerName} (${brokerLocation})`;
	}, [brokerName, brokerLocation]);

	return (
		<Paper>
			<Box testId="card-container" style={{ gridTemplateColumns: 'repeat(12,1fr)' }} gap="sm" width="full" display="grid" flexWrap="wrap">
				<Box overflow="hidden" gridSpan={getValueByScreenSizes({ lg: 5, md: 5, sm: 12 })} display="flex" gap="sm">
					<Box display="flex" justifyContent="center" alignItems="center" style={{ cursor: 'pointer' }} onClick={!moreInfoDisabled && moreInfoCallback}>
						<Avatar.Multiple loading={loading} variant="square" srcs={imageUrls} fallbackIcon="business" />
					</Box>
					<Box width="full" overflow="hidden" display="flex" gap="xxs" justifyContent="center" flexDirection="column" height="auto">
						<Card.Title website={website} loading={loading} onClick={!moreInfoDisabled && moreInfoCallback}>
							{name}
						</Card.Title>
						<Labels.LocationAndClients location={location} loading={loading} />
						<TextOverflowTooltip label={description}>
							{(overflowCallback) => (
								<Typography.Text
									whiteSpace="nowrap"
									ellipsedCallback={overflowCallback}
									ellipsis
									style={{ textAlign: 'start' }}
									color="neutral-500"
									weight="regular"
									size="sm"
								>
									{description}
								</Typography.Text>
							)}
						</TextOverflowTooltip>
					</Box>
				</Box>
				<Box
					overflow="hidden"
					gridSpan={getValueByScreenSizes({ lg: 5, md: 5, sm: 12 })}
					gap="sm"
					display="grid"
					style={{ gridTemplateColumns: 'repeat(12,1fr)' }}
				>
					<Box gridSpan={getValueByScreenSizes({ lg: 6, md: 12, sm: 12 })} gap="xs" display="flex" flexDirection="column" height="auto" justifyContent="center">
						{(renewalDate || loading) && <Description value={renewalDate} loading={loading} icon="calendar-check" label="renewal" />}
						{(fundingType || loading) && <Description value={fundingType} loading={loading} icon="bills" />}
					</Box>
					<Box
						gridSpan={getValueByScreenSizes({ lg: 6, md: 12, sm: 12 })}
						gap="xs"
						display="flex"
						flexDirection="column"
						height="auto"
						justifyContent="center"
						overflow="hidden"
					>
						{(numberOfEmployees || loading) && (
							<Description
								value={numberOfEmployees}
								containerProps={{ testId: 'employer-card-number-of-employees' }}
								loading={loading}
								icon="users"
								label="Employees"
							/>
						)}
						{(brokerLabel || loading) && (
							<Description
								loading={loading}
								icon="business"
								value={
									<TextOverflowTooltip label={brokerLabel}>
										{(overflowCallback) => (
											<Typography.Text
												ellipsis
												data-testid="broker-location"
												ellipsedCallback={overflowCallback}
												onClick={onClickBrokerLocation}
												color="primary-500"
												decoration="underline"
												style={{ cursor: 'pointer' }}
												size="sm"
												whiteSpace="nowrap"
											>
												{brokerLabel}
											</Typography.Text>
										)}
									</TextOverflowTooltip>
								}
							/>
						)}
					</Box>
				</Box>
				<Box
					gridSpan={getValueByScreenSizes({ lg: 2, md: 2, sm: 12 })}
					height="auto"
					display="flex"
					justifyContent={getValueByScreenSizes({ lg: 'end', md: 'end', sm: 'center' })}
					alignItems="center"
				>
					{!moreInfoDisabled && (
						<Button testId="more-info-button" disabled={moreInfoDisabled} onClick={moreInfoCallback} loading={loading}>
							More Info
						</Button>
					)}
				</Box>
			</Box>
		</Paper>
	);
};
