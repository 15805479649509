import { Location } from './components';
import { useServiceProvidersStore } from '../../ServiceProviders.store';
import { Filters as FiltersComponent } from '../../../../../../shared/Filters/Filters';

export const Filters = () => {
	const { clearFilters } = useServiceProvidersStore();

	return (
		<FiltersComponent onClear={clearFilters}>
			<FiltersComponent.Section title="Service Provider Attributes" />
			<Location />
		</FiltersComponent>
	);
};
