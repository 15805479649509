import { Box } from '@benefitflow/designsystem';

import { Count, ExportButton } from './components';

export const Header = ({ resultsCount, isLoading }: { resultsCount: number; isLoading: boolean }) => {
	return (
		<Box display="flex" justifyContent="between" alignItems="center">
			<Count resultsCount={resultsCount} isLoading={isLoading} />
			<ExportButton isLoading={isLoading} />
		</Box>
	);
};
