import { useEffect, useState } from 'react';

export const useSidebarDimensions = ({ filters, ref }: { ref: React.MutableRefObject<HTMLDivElement>; filters }) => {
	const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

	useEffect(() => {
		if (ref?.current) setDimensions({ width: ref.current.offsetWidth, height: ref.current.offsetHeight });
	}, [ref, filters]);

	return dimensions;
};
