import React, { useRef } from 'react';

import { List } from './components/List/List';
import { Filters, Header } from './components';
import { SearchBar } from '../components';
import { TabLayout } from '../../../shared/TabLayout/TabLayout';
import { useContactsQuery } from '../../../../queries';
import { useNumberOfSelectedContacts } from '../hooks';
import { useResetCheckedMapOnFiltersChange } from './hooks';
import { useFilterOptions } from './hooks/useFilterOptions';
import { useContacts } from '../../../../hooks/useContacts';
import { useBrokerContactsStore } from './BrokerContacts.store';
import { useSidebarDimensions } from './hooks/useSidebarHeight';
import { ContactTypeEnum } from 'common.model/src/types/ContactTypeEnum';

export const BrokerContacts = () => {
	const store = useBrokerContactsStore();
	const { data, fetchNextPage, isInitialLoading, isFetching, hasNextPage, isLoading } = useContactsQuery({
		contactType: ContactTypeEnum.BROKER,
		options: {
			getNextPageParam: (lastPage) => {
				if (!lastPage?.anonymizedContacts?.length) return null;
				return lastPage.page + 1;
			},
		},
		filters: store.filters,
	});
	const contacts = useContacts({ data, store });
	const filterOptions = useFilterOptions();
	const numberOfSelectedContacts = useNumberOfSelectedContacts({
		checkedMap: store.checkedMap,
		checkMode: store.checkMode,
		totalNumberOfContacts: filterOptions?.data?.totalHits ?? 0,
	});
	const sidebarRef = useRef<HTMLDivElement>(null);
	const { height: minHeight } = useSidebarDimensions({ ref: sidebarRef, filters: store.filters });
	useResetCheckedMapOnFiltersChange();

	return (
		<TabLayout
			sidebarRef={sidebarRef}
			filters={<Filters breakdowns={filterOptions?.data?.breakdowns ?? {}} />}
			buttons={
				<Header
					contacts={contacts}
					isLoading={isLoading || filterOptions.loading}
					numberOfSelectedContacts={numberOfSelectedContacts}
					totalNumberOfContacts={filterOptions?.data?.totalHits ?? 0}
				/>
			}
			list={
				<List
					isLoading={isInitialLoading}
					contacts={contacts ?? []}
					fetchNextPage={fetchNextPage}
					hasNextPage={hasNextPage}
					isFetching={isFetching}
					minHeight={minHeight}
				/>
			}
			search={<SearchBar value={store.filters.nameSearch} setValue={store.setFilter.nameSearch} placeholder="Search broker contacts" />}
		/>
	);
};
