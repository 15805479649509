import { List } from '../../components/pages/List/lib/entities/list';
import { useContext, useMemo, useState } from 'react';
import { PaginationParams, SortKey, SortOrder } from 'common.model/src/types/Pagination';
import { SetSortParams } from 'new-beginning/hooks/salesforce/useListContacts';
import { APIContext } from 'new-beginning/services/api/Api.context';
import { ListType } from 'common.model/src/db/model-decorators/type-extensions';

export const useGetList = (listType?: ListType) => {
	const { listsApi } = useContext(APIContext);
	const [currentSorting, setCurrentSorting] = useState<PaginationParams>(undefined);

	const handleSorting = (args: SetSortParams) => {
		setCurrentSorting({ sortKey: args.sortKey, sortOrder: args.sortOrder, offset: 0, limit: 0 });
	};

	const { data, loading } = listsApi.useGetAllListsForUser({
		// refetchOnMount: true,
		// refetchOnReconnect: true,
		refetchInterval: Infinity,
		refetchOnWindowFocus: true,		
		// staleTime: 0,
		// cacheTime: 1000
	});

	const sortedData = useMemo(() => {
		const listData = data?.flatMap((row) => {
			if (row.id !== -1 && listType && row.list_type !== listType) {
				return [];
			}
			const list = new List(row);
			// Hack: Use the current list type's contact count for the -1 list
			// This allows us to display the most relevant contact count in the list table
			if (list.id === -1) {
				list.contacts = listType === ListType.BROKER_CONTACT ? list.brokerContacts : list.employerContacts;
			}
			return [list];
		});
		switch (currentSorting?.sortKey) {
			case SortKey.ListName:
			case SortKey.ListCreatedAt:
			case SortKey.ListCreatedBy:
			case SortKey.ListContacts:
				if (currentSorting.sortOrder === SortOrder.ASC) {
					return listData?.sort((a, b) => (a[currentSorting.sortKey] < b[currentSorting.sortKey] ? 1 : -1));
				}
				return listData?.sort((a, b) => (a[currentSorting.sortKey] < b[currentSorting.sortKey] ? -1 : 1));
			default:
				return listData;
		}
	}, [currentSorting, data, listType]);

	return { data: sortedData, isLoading: loading, handleSorting, currentSorting };
};
