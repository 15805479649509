import { create } from 'zustand';
import { getDefaultBrokerContactFilters } from 'common.model/src/types/common/search/filters/ContactFilters';

import { StoreUtils } from '../../../../../utilities';
import { BrokerContactsDetailsStore } from './Contacts.types';

export const FILTERS = [
	'contactLocation' as const,
	'carrierAppointments' as const,
	'jobTitles' as const,
	'licenseTypes' as const,
	'roles' as const,
	'yearsAtCompany' as const,
	'brokerOfficeLocations' as const,
];

export const useBrokerContactsDetailsStore = create<BrokerContactsDetailsStore>((set) => ({
	...StoreUtils.createFilterVisibilityMethods(FILTERS, set),
	...StoreUtils.createContactSelectorMethods(set),
	filters: getDefaultBrokerContactFilters(),
	setFilters: {
		contactLocation: StoreUtils.createLocationMethods('contactLocation', set),
		brokerOfficeLocations: StoreUtils.createLocationMethods('brokerOfficeLocations', set),
		carrierAppointments: StoreUtils.createIncludeExcludeMethods('carrierAppointments', set),
		jobTitles: StoreUtils.createIncludeExcludeMethods('jobTitles', set),
		licenseTypes: StoreUtils.createIncludeExcludeMethods('licenseTypes', set),
		roles: StoreUtils.createIncludeExcludeMethods('roles', set),
		yearsAtCompany: StoreUtils.createRangeMethodsLegacy('yearsAtCompany', set),
		companyMasterIds: {
			set: (companyMasterIds: string[]) =>
				set((state) => ({
					filters: { ...state.filters, companyMasterIds },
				})),
		},
	},
	clearFilters: () => set({ filters: getDefaultBrokerContactFilters() }),
}));
