import { MutableRefObject, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { DOMUtils } from '../../../../../../../../../utilities';

export const useScrollToOfficeClient = ({
	isFetched,
	set,
	containerRef,
}: {
	isFetched: boolean;
	set: (rowId: string, expanded: boolean) => void;
	containerRef: MutableRefObject<HTMLDivElement>;
}) => {
	const [searchParams] = useSearchParams();

	useEffect(() => {
		if (isFetched) {
			const brokerLocation = searchParams.get('brokerLocation');
			const employer = searchParams.get('employer');
			set(brokerLocation, true);
			void (async () => {
				if (brokerLocation)
					await DOMUtils.waitForElement(
						brokerLocation,
						(element) => element.scrollIntoView({ behavior: 'smooth', inline: 'end', block: 'center' }),
						containerRef.current,
					);
				await new Promise((resolve) => setTimeout(resolve, 2000));
				if (employer) {
					await DOMUtils.waitForElement(
						employer,
						(element) => {
							element.style.backgroundColor = '#EDF2FC';
							element.scrollIntoView({ behavior: 'smooth', inline: 'end', block: 'center' });
						},
						containerRef.current,
					);
				}
			})();
		}
	}, [isFetched, searchParams, set, containerRef]);
};
