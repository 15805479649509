import { Skeleton, Typography } from '@benefitflow/designsystem';

export const Labels = {
	Name: ({ value, loading }: { value: string; loading: boolean }) => {
		if (loading) return <Skeleton height="md" width="xl4" />;
		if (!value) return null;
		return <Typography.Text>{value}</Typography.Text>;
	},
	NumberOfClients: ({ value, loading }: { value: string; loading: boolean }) => {
		if (loading) return <Skeleton height="md" width="xl2" />;
		if (!value) return null;
		return (
			<Typography.Text color="neutral-500" weight="regular" size="sm">
				{value}
			</Typography.Text>
		);
	},
};
