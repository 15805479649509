import { useContext, useMemo } from 'react';
import { Button } from '@benefitflow/designsystem';

import { useExportEmployers, useExportPolicyDetails } from './hooks';
import { ExportContext } from '../../../../../../../../context/ExportContext';

export const ExportButton = ({ isLoading }: { isLoading: boolean }) => {
	const exportEmployers = useExportEmployers();
	const exportPolicyDetails = useExportPolicyDetails();
	const { isExporting } = useContext(ExportContext);
	const options = useMemo(
		() => [
			{
				label: 'Export Employers',
				onClick: exportEmployers,
			},
			{
				label: 'Export Policy Details',
				onClick: exportPolicyDetails,
			},
		],
		[exportEmployers, exportPolicyDetails],
	);

	return (
		<Button.MultipleOptions placement="bottom-end" options={options} loading={isLoading} fetching={isExporting} variant="outlined" icon="download">
			Export
		</Button.MultipleOptions>
	);
};
