import { useContext } from 'react';

import { useEmployerContactsStore } from '../../EmployerContacts.store';
import { EmployerContactsContext } from '../../EmployerContacts.context';
import { LOCATION_INPUT_PLACEHOLDER } from '../../../../../../../app/constants';
import { Filters as FiltersComponent } from '../../../../../shared/Filters/Filters';
import { useContactLocation, useEmployeeCount, useEmployerLocation, useRenewalDate, useYearsAtCompany } from './hooks';

export const Filters = () => {
	const { filterOptions } = useContext(EmployerContactsContext);
	const { filters, filtersVisibilityMap, toggleFilterVisibility, setFilters, resetFiltersVisibility, resetFilterValues } = useEmployerContactsStore();
	const employerLocation = useEmployerLocation(filters);
	const contactLocation = useContactLocation();
	const yearsAtCompany = useYearsAtCompany();
	const employeeCount = useEmployeeCount();
	const renewalDate = useRenewalDate();

	return (
		<FiltersComponent
			onClear={() => {
				resetFiltersVisibility();
				resetFilterValues();
			}}
		>
			<FiltersComponent.Section title="Contact Attributes" />
			<FiltersComponent.IncludeExcludeFilter
				title="Company Name"
				icon="briefcase"
				testId=""
				placeholder="e.g. Mercer"
				description="Contacts employed at [Mercer]"
				isLoading={false}
				isOpen={filtersVisibilityMap.companyName}
				onDelete={({ type, value }) => setFilters.companyName[type].remove(value)}
				onSelect={({ type, value }) => setFilters.companyName[type].add(value)}
				onToggle={toggleFilterVisibility.companyName}
				options={filterOptions?.companyName ?? []}
				selectedOptions={filters.companyName}
			/>
			<FiltersComponent.LocationFilter
				icon="location"
				title="Contact Location"
				testId=""
				description="Contacts located in [California]"
				placeholder={LOCATION_INPUT_PLACEHOLDER}
				isLoading={contactLocation.isLoading}
				setRadiusMiles={setFilters.contactLocation.setRadiusMiles}
				isOpen={filtersVisibilityMap.contactLocation}
				onDelete={({ type, value }) => setFilters.contactLocation[type].remove({ searchValue: value })}
				onSelect={({ type, value }) => setFilters.contactLocation[type].add(value)}
				onSearch={contactLocation.onSearch}
				options={contactLocation.options}
				selectedOptions={filters.contactLocation}
				onToggle={toggleFilterVisibility.contactLocation}
			/>
			<FiltersComponent.IncludeExcludeFilter
				title="Role"
				icon="users"
				description="Contacts that act as [executives]"
				placeholder="e.g Producer"
				testId=""
				isLoading={false}
				isOpen={filtersVisibilityMap.roles}
				onDelete={({ type, value }) => setFilters.roles[type].remove(value)}
				onSelect={({ type, value }) => setFilters.roles[type].add(value)}
				onToggle={toggleFilterVisibility.roles}
				options={filterOptions?.roles ?? []}
				selectedOptions={filters.roles}
			/>
			<FiltersComponent.IncludeExcludeFilter
				title="Job Title"
				icon="briefcase"
				description="Contacts that have a job title of [head of HR]"
				placeholder="e.g. Consultant"
				testId=""
				isLoading={false}
				isOpen={filtersVisibilityMap.jobTitles}
				onDelete={({ type, value }) => setFilters.jobTitles[type].remove(value)}
				onSelect={({ type, value }) => setFilters.jobTitles[type].add(value)}
				onToggle={toggleFilterVisibility.jobTitles}
				options={filterOptions?.jobTitles ?? []}
				selectedOptions={filters.jobTitles}
			/>
			<FiltersComponent.RangeFilter
				title={'Years At Company'}
				description={'Contacts that have [<2] years at current company'}
				isLoading={false}
				testId=""
				defaultOption={{ start: '0', end: '21' }}
				onToggle={toggleFilterVisibility.yearsAtCompany}
				isOpen={filtersVisibilityMap.yearsAtCompany}
				options={yearsAtCompany.options}
				onSelect={setFilters.yearsAtCompany}
				selectedRange={{ start: filters.yearsAtCompany[0], end: filters.yearsAtCompany[1] }}
				icon="calendar"
			/>
			<FiltersComponent.Section title="Employer Attributes" />
			<FiltersComponent.CustomRangeFilter
				title="Employee Count"
				description="Employers with [0 - 10k] employees"
				icon="users"
				defaultOption={{ start: '0', end: '3000000' }}
				isLoading={false}
				isOpen={filtersVisibilityMap.employerTotalEmployees}
				onSelect={setFilters.employerTotalEmployees}
				onToggle={toggleFilterVisibility.employerTotalEmployees}
				options={employeeCount.options}
				selectedRange={filters.employerTotalEmployees}
				testId=""
			/>
			<FiltersComponent.LocationFilter
				icon="location"
				title="HQ Location"
				testId=""
				description="Employers located in [California]"
				placeholder={LOCATION_INPUT_PLACEHOLDER}
				isLoading={contactLocation.isLoading}
				setRadiusMiles={setFilters.employerHeadquarters.setRadiusMiles}
				isOpen={filtersVisibilityMap.employerHeadquarters}
				onDelete={({ type, value }) => setFilters.employerHeadquarters[type].remove({ searchValue: value })}
				onSelect={({ type, value }) => setFilters.employerHeadquarters[type].add(value)}
				onSearch={employerLocation.onSearch}
				options={employerLocation.options}
				selectedOptions={filters.employerHeadquarters}
				onToggle={toggleFilterVisibility.employerHeadquarters}
			/>
			<FiltersComponent.IncludeExcludeFilter
				title="Industry"
				icon="briefcase"
				placeholder="e.g. Information or Utilities"
				testId=""
				description="Employers in the [utilities] industry"
				isLoading={false}
				isOpen={filtersVisibilityMap.employerIndustries}
				onDelete={({ type, value }) => setFilters.employerIndustries[type].remove(value)}
				onSelect={({ type, value }) => setFilters.employerIndustries[type].add(value)}
				onToggle={toggleFilterVisibility.employerIndustries}
				options={filterOptions?.employerIndustries ?? []}
				selectedOptions={filters.employerIndustries}
			/>
			<FiltersComponent.IncludeExcludeFilter
				title="Employer Type"
				icon="sitemap"
				placeholder="e.g Single-Employer or Multiemployer"
				testId=""
				description="Employers that are [multiemployer]"
				isLoading={false}
				isOpen={filtersVisibilityMap.employerEntityType}
				onDelete={({ type, value }) => setFilters.employerEntityType[type].remove(value)}
				onSelect={({ type, value }) => setFilters.employerEntityType[type].add(value)}
				onToggle={toggleFilterVisibility.employerEntityType}
				options={filterOptions?.employerEntityType ?? []}
				selectedOptions={filters.employerEntityType}
			/>
			<FiltersComponent.IncludeExcludeFilter
				title="Funding"
				description="Employers that are [self-funded]"
				placeholder="e.g. Fully-Insured or Self-Funded"
				icon="bills"
				testId=""
				isLoading={false}
				isOpen={filtersVisibilityMap.employerPlanType}
				onDelete={({ type, value }) => setFilters.employerPlanType[type].remove(value)}
				onSelect={({ type, value }) => setFilters.employerPlanType[type].add(value)}
				onToggle={toggleFilterVisibility.employerPlanType}
				options={filterOptions?.employerPlanType ?? []}
				selectedOptions={filters.employerPlanType}
			/>
			<FiltersComponent.IncludeExcludeFilter
				title="Insurance Products"
				icon="shield"
				description="Employers with [dental] products"
				placeholder="e.g. Dental or Health"
				testId=""
				isLoading={false}
				isOpen={filtersVisibilityMap.employerLineOfBusiness}
				onDelete={({ type, value }) => setFilters.employerLineOfBusiness[type].remove(value)}
				onSelect={({ type, value }) => setFilters.employerLineOfBusiness[type].add(value)}
				onToggle={toggleFilterVisibility.employerLineOfBusiness}
				options={filterOptions?.employerLineOfBusiness ?? []}
				selectedOptions={filters.employerLineOfBusiness}
			/>
			<FiltersComponent.IncludeExcludeFilter
				title="Carrier"
				description="Employers with [Aetna] products"
				icon="business"
				placeholder="e.g. Aetna or Humana"
				testId=""
				isLoading={false}
				isOpen={filtersVisibilityMap.employerCarrierName}
				onDelete={({ type, value }) => setFilters.employerCarrierName[type].remove(value)}
				onSelect={({ type, value }) => setFilters.employerCarrierName[type].add(value)}
				onToggle={toggleFilterVisibility.employerCarrierName}
				options={filterOptions?.employerCarrierName ?? []}
				selectedOptions={filters.employerCarrierName}
			/>
			<FiltersComponent.RangeFilter
				title={'Renewal Date'}
				defaultOption={{ start: '1', end: '12' }}
				testId=""
				description={'Employers with [July] renewals'}
				isLoading={false}
				isOpen={filtersVisibilityMap.employerRenewalDatesRange}
				onToggle={toggleFilterVisibility.employerRenewalDatesRange}
				options={renewalDate.options}
				onSelect={setFilters.employerRenewalDatesRange}
				selectedRange={filters.employerRenewalDatesRange}
				icon="calendar"
			/>
			<FiltersComponent.IncludeExcludeFilter
				title="PEO"
				icon="peo"
				description="Employers with [ADP] as a PEO"
				allOptionsLabel="Any PEO"
				placeholder="e.g. ADP or TriNet"
				testId=""
				isLoading={false}
				isOpen={filtersVisibilityMap.employerPEOName}
				onDelete={({ type, value }) => setFilters.employerPEOName[type].remove(value)}
				onSelect={({ type, value }) => setFilters.employerPEOName[type].add(value)}
				onToggle={toggleFilterVisibility.employerPEOName}
				options={filterOptions?.employerPEOName ?? []}
				selectedOptions={filters.employerPEOName}
			/>
			<FiltersComponent.IncludeExcludeFilter
				title="Accounting Firm"
				description="Employers that use [KPMG] for accounting"
				placeholder="e.g. KPMG or Deloitte"
				icon="calculator"
				isLoading={false}
				allOptionsLabel="Any Accounting Firm"
				isOpen={filtersVisibilityMap.employerAccountantFirmName}
				onDelete={({ type, value }) => setFilters.employerAccountantFirmName?.[type].remove(value)}
				onSelect={({ type, value }) => setFilters.employerAccountantFirmName?.[type].add(value)}
				options={filterOptions?.employerAccountantFirmName}
				onToggle={toggleFilterVisibility.employerAccountantFirmName}
				selectedOptions={filters.employerAccountantFirmName}
				testId=""
			/>
		</FiltersComponent>
	);
};
