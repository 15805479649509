import { useContext } from 'react';
import { BrokerFilters } from 'common.model/src/types/common/search/filters/CompanyFilters';

import { APIContext } from 'new-beginning/services/api/Api.context';
import FormattingUtils from '../../../ui-components/utils/FormattingUtils';
import { BrokerOfficeResult } from 'common.services/src/search/BrokerElasticSearchCache';

export const useBrokerSummaryQuery = (filters: BrokerFilters) => {
	const { brokerApi } = useContext(APIContext);
	const query = brokerApi.useGetBrokerOffices(filters, { select });

	return query;
};

export interface BrokerSummaryDTO {
	imageSrcs?: string[];
	name?: string;
	address?: string;
	numberOfContacts?: string;
	linkedinUrl?: string;
	websiteUrl?: string;
	comissions?: string;
	premiums?: string;
	offices?: string;
	clients?: string;
}

export const select = (data: BrokerOfficeResult[]): BrokerSummaryDTO => {
	const o = data[0];

	return {
		name: o?.masterBrokerName,
		address: o?.headquarters,
		clients: o?.clientCount && FormattingUtils.formatCommas(o.clientCount),
		imageSrcs: (() => {
			const imageUrl = o?.linkedinProfileUrl
				?.substring(o?.linkedinProfileUrl.indexOf('company') + 8)
				.split('/')[0]
				.split('?')[0];
			const stage = import.meta.env.VITE_STAGE?.trim() === 'prod' ? 'prod' : 'qa'; // beside prod both for qa and local we will use qa
			return [`https://benefit-flow-data-broker-images-${stage}.s3.amazonaws.com/${imageUrl}.jpg`, `https://logo.clearbit.com/${o?.website}`];
		})(),
		linkedinUrl: o?.linkedinProfileUrl,
		numberOfContacts: o?.contactCount && FormattingUtils.formatCommas(o.contactCount),
		offices: o?.officeLocationCount && FormattingUtils.formatCommas(o?.officeLocationCount),
		premiums: o?.carrierPremiums && `$${FormattingUtils.formatNumberAbbrev(o.carrierPremiums) as string}`,
		comissions: o?.commissions && `$${FormattingUtils.formatNumberAbbrev(o?.commissions) as string}`,
		websiteUrl: o?.website,
	};
};
