import React, { useRef } from 'react';

import FilterPageTemplate from 'templates/FilterPageTemplate';
import { ContactsCardsListProps } from '../organisms/ContactsCardsList';
import { ContactFiltersProps } from 'organisms/ContactFilters/ContactFilters';
import { ListDetailSummaryCard, ListDetailSummaryCardProps } from '../molecules/ListDetailSummaryCard';
import { ListDetails } from 'new-beginning/components/shared/SidebarFilters/components/Tabs/components';
import { ContactsList } from '../../../../../../new-beginning/components/pages/ContactsList/ContactsList';
import { MoveContactListChooserModal, MoveContactListChooserModalProps } from '../organisms/MoveContactListChooserModal';
import { ListAttributesWithCounts } from 'common.model/src/db/models/List';

export const BROKER_CONTACT_TAB = 0;
export const EMPLOYER_CONTACT_TAB = 1;

export interface ListsDetailPageProps {
	moveContactListChooserModal: MoveContactListChooserModalProps;
	onBack: () => void;
	contactsFilterBar: ContactFiltersProps;
	contactsCardList: ContactsCardsListProps;
	summaryCard: ListDetailSummaryCardProps;
	onClearContactFilters: () => void;
	contactTypeTab: number;
	setContactTypeTab: (contactTypeTabNum: number) => void;
}

export const ListsDetailPage = (props: ListsDetailPageProps) => {
	const sidebarRef = useRef<HTMLDivElement>(null);
	return (
		<>
			<FilterPageTemplate
				title={'Lists'}
				onBack={props.onBack}
				filterBar={
					<div ref={sidebarRef}>
						<ListDetails />
					</div>
				}
				summaryTile={
					<div className="dev-detail-panel">
						<ListDetailSummaryCard {...props.summaryCard} />
					</div>
				}
				mainContent={
					<>
						<ContactsList
							sidebarRef={sidebarRef}
							setExternalTabStore={props.setContactTypeTab}
							isLoadingFilters={props.contactsFilterBar.loading}
							filters={props.contactsFilterBar.filters}
							totalNumberOfContacts={props.contactsCardList.total}
						/>
					</>
				}
			/>
		</>
	);
};
