import { ListType } from "common.model/src/db/model-decorators/type-extensions";
import { ListAttributesWithCounts } from "common.model/src/db/models/List";

export interface ListApiResponse {
	id: number;
	name: string;
	description: string;
	created_at?: string;
	num_broker_contacts: string;
	num_employer_contacts: string;
	created_by: string;
}

export class List {
	id: number;
	name: string;
	createdAt?: Date | null;
	listType: ListType;
	brokerContacts: number;
	employerContacts: number;
	contacts: number;
	createdBy: string;

	constructor(data: ListAttributesWithCounts) {
		this.id = data.id;
		this.name = data.name;
		this.brokerContacts = Number(data.num_broker_contacts || 0);
		this.employerContacts = Number(data.num_employer_contacts || 0);
		this.contacts = this.brokerContacts + this.employerContacts;
		this.createdAt = data.created_at ? new Date(data.created_at) : null;
		this.createdBy = data.created_by;
		this.listType = data.list_type as ListType;
	}

	get getLocaleCreatedDate() {
		if (this.createdAt) {
			return this.createdAt.toLocaleDateString();
		}
		return '-';
	}

	get getCuratedName() {
		if (this.id < 0) {
			return 'All Contacts';
		}
		return this.name.replace(/\[|\]/g, '');
	}

	get getFormatedContacts() {
		return this.contacts.toLocaleString('es-US');
	}
}
