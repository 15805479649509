import { useCallback, useContext } from 'react';

import { useEmployersStore } from '../../../../../Employers.store';
import { ExportContext } from '../../../../../../../../../context/ExportContext';

export const useExportEmployers = () => {
	const { filters } = useEmployersStore();
	const { onExport } = useContext(ExportContext);
	const exportEmployers = useCallback(() => onExport({ type: 'employer', filters }), [onExport, filters]);

	return exportEmployers;
};
