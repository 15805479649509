import { useCallback } from 'react';

import { useServiceProvidersStore } from '../../../../../ServiceProviders.store';

export const useOnDelete = () => {
	const { setFilters } = useServiceProvidersStore();
	const onDelete = useCallback(
		(value: string) => () => {
			setFilters.location.remove(value);
		},
		[setFilters],
	);

	return onDelete;
};
