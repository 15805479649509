import { useContext } from 'react';
import { APIContext } from '../services/api/Api.context';
import { InfiniteData, useInfiniteQuery, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { ProviderSearchResponse } from 'common.model/src/types/services/provider-service/ProviderService';
import { FormattingUtils } from '../../ui-components';

export interface ServiceProviderDTO {
	id: string;
	name: string;
	fees: string;
	states: string;
}

export const useServiceProvidersQuery = ({
	location,
	search,
	options,
}: {
	search: string;
	location: string[];
	options?: UseInfiniteQueryOptions<ProviderSearchResponse, unknown, Omit<ProviderSearchResponse, 'data'> & { data: ServiceProviderDTO[] }>;
}) => {
	const { providerApi } = useContext(APIContext);
	const query = useInfiniteQuery<ProviderSearchResponse, unknown, Omit<ProviderSearchResponse, 'data'> & { data: ServiceProviderDTO[] }>(
		['serviceProviders', location, search],
		async (params) => providerApi.useSearch({ page: params.pageParam ?? 0, search, states: location }),
		{
			...options,
			select: _select,
		},
	);

	return query;
};

export const _select = (data: InfiniteData<ProviderSearchResponse>): InfiniteData<Omit<ProviderSearchResponse, 'data'> & { data: ServiceProviderDTO[] }> => {
	return {
		...data,
		pages: data.pages.map(({ data, ...attributes }) => ({
			data: data.map((raw) => ({
				id: raw.master_provider_id,
				name: raw.master_provider_name,
				fees: `$${FormattingUtils.formatNumberAbbrev(raw.fees_accounted_for)}`,
				states: raw.state_list,
			})),
			...attributes,
		})),
	};
};
