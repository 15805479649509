import { ContactTypeEnum } from 'common.model/src/types/ContactTypeEnum';
import { IncludeExcludeField, Range } from './filters';
import { LocationSelection } from 'common.model/src/types/master/location';

/*
	Common public filters across all contact types
*/
export interface BaseContactFilters {
	nameSearch: string;
	companyMasterIds: string[];
	jobTitles: IncludeExcludeField<string[]>;
	yearsAtCompany: number[];
	roles: IncludeExcludeField<string[]>;
	contactLocation: IncludeExcludeField<LocationSelection[]>;
	page: number | null;
	companyName: IncludeExcludeField<string[]>;

	// private
	contactType: ContactTypeEnum;
	profileUrls: string[];
	excludedProfileUrls: string[];
	withFullContactData: boolean;
	listIds: string[];
	tenantIds: string[];
}

/*
	Employer specific filters
*/
export interface EmployerContactFilters extends BaseContactFilters {
	employerTotalEmployees: Range;
	employerHeadquarters: IncludeExcludeField<LocationSelection[]>;
	employerIndustries: IncludeExcludeField<string[]>;
	employerEntityType: IncludeExcludeField<string[]>;
	employerPlanType: IncludeExcludeField<string[]>;
	employerLineOfBusiness: IncludeExcludeField<string[]>;
	employerCarrierName: IncludeExcludeField<string[]>;
	employerRenewalDatesRange: Range;
	employerPEOName: IncludeExcludeField<string[]>;
	employerAccountantFirmName: IncludeExcludeField<string[]>;
}

/*
	Broker specific filters
*/
export interface BrokerContactFilters extends BaseContactFilters {
	brokerSize: IncludeExcludeField<string[]>;
	brokerOfficeLocations: IncludeExcludeField<LocationSelection[]>;
	licenseTypes: IncludeExcludeField<string[]>;
	carrierAppointments: IncludeExcludeField<string[]>;
}

export type AllContactFilters = EmployerContactFilters & BrokerContactFilters;

/*
	Default filters for all contact types (mostly for the frontend)
*/
const getDefaultBaseContactFilters = (): BaseContactFilters => ({
	nameSearch: '',
	companyMasterIds: [],
	jobTitles: { exclude: [], include: [] },
	yearsAtCompany: [0, 21],
	roles: { exclude: [], include: [] },
	contactLocation: { exclude: [], include: [] },
	page: 0,
	companyName: { exclude: [], include: [] },
	contactType: null,
	profileUrls: [],
	excludedProfileUrls: [],
	withFullContactData: false,
	listIds: [],
	tenantIds: [],
});

const getDefaultEmployerContactFilters = (): EmployerContactFilters => ({
	...getDefaultBaseContactFilters(),
	contactType: ContactTypeEnum.EMPLOYER,
	employerTotalEmployees: { start: 0, end: 3000000 },
	employerHeadquarters: { exclude: [], include: [] },
	employerIndustries: { exclude: [], include: [] },
	employerEntityType: { exclude: [], include: [] },
	employerPlanType: { exclude: [], include: [] },
	employerLineOfBusiness: { exclude: [], include: [] },
	employerCarrierName: { exclude: [], include: [] },
	employerRenewalDatesRange: { start: 1, end: 12 },
	employerPEOName: { exclude: [], include: [] },
	employerAccountantFirmName: { exclude: [], include: [] },
});

const getDefaultBrokerContactFilters = (): BrokerContactFilters => ({
	...getDefaultBaseContactFilters(),
	contactType: ContactTypeEnum.BROKER,
	brokerSize: { exclude: [], include: [] },
	brokerOfficeLocations: { exclude: [], include: [] },
	licenseTypes: { exclude: [], include: [] },
	carrierAppointments: { exclude: [], include: [] },
});

export { getDefaultEmployerContactFilters, getDefaultBrokerContactFilters };
