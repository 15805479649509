import { InfinityList } from '@benefitflow/designsystem';

import { Row } from './components';
import { ContactDTO } from '../../../../../../../queries';
import { useScreenSize } from '../../../../../../../hooks/useScreenSize';
import { useHeight } from '../../../../../Search/BrokerContactCardV2/hooks';
import { BrokerContactCardV2 } from '../../../../../Search/BrokerContactCardV2/BrokerContactCardV2';

export const List = ({
	contacts,
	isLoading,
	isFetching,
	minHeight,
	fetchNextPage,
	hasNextPage,
}: {
	minHeight: number;
	contacts: ContactDTO[];
	isLoading: boolean;
	isFetching: boolean;
	fetchNextPage: () => void;
	hasNextPage: boolean;
}) => {
	const screenSize = useScreenSize();
	const height = useHeight();

	return (
		<InfinityList
			fetchNextPage={fetchNextPage}
			getLoaderRow={(index) => <BrokerContactCardV2 key={index} variant="profile" loading />}
			getRow={({ data, index }) => <Row key={index} contact={data} />}
			getRowHeight={() => height}
			hasNextPage={hasNextPage}
			height={'calc(100vh - 20rem)'}
			gap={12}
			isFetching={isFetching}
			isLoading={isLoading}
			rows={contacts}
			minHeight={minHeight}
			screenSize={screenSize}
		/>
	);
};
