import { useCallback, useContext, useMemo, useState } from 'react';

import { APIContext } from '../../../../../../../services/api/Api.context';
import { LocationUtils } from '../../../../../../../utilities';

export const useEmployerLocation = (filters) => {
	const [search, setSearch] = useState('');
	const { employerApi } = useContext(APIContext);
	const { data, loading } = employerApi.useSearchEmployerHq({ ...filters, employerHqSearch: search });
	const onSearch = useCallback((value: string) => setSearch(value), [setSearch]);
	const options = useMemo(() => data?.map(LocationUtils.parseLocation) ?? [], [data]);

	return { options, isLoading: loading, onSearch };
};
