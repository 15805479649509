import { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { addNotification } from '@benefitflow/designsystem';

import { APIContext } from 'new-beginning/services/api/Api.context';
import { useContactsListStore } from '../../../../../ContactsList.store';
import { ContactsListContext } from '../../../../../ContactsList.context';
import { BrokerContactFilters } from 'common.model/src/types/common/search/filters/ContactFilters';
import { getApiPayloadFromState } from '../../../../../../Search/helpers/getApiPayloadFromState';

export const useDeleteSelected = () => {
	const { listsApi } = useContext(APIContext);
	const { listId } = useParams();
	const { checkedMap, checkMode, tab } = useContactsListStore();
	const { filters } = useContext(ContactsListContext);
	const { data } = listsApi.useGetAllListsForUser();
	const listName = useMemo(() => data?.find((list) => list.id == Number(listId))?.name, [data, listId]);

	const { checkedMap: contactsCheckedMap, notCheckedMap: contactsNotCheckedMap } = getApiPayloadFromState({ checkedMap, checkMode });
	const { mutate, loading } = listsApi.useDeleteListItems(
		{
			contactsCheckedMap,
			contactsNotCheckedMap,
			filters: filters as BrokerContactFilters, // TODO: We should fix this on the backend to be able to support EmployerContactFilters as well!
			isSelectAll: checkMode === 'select-all',
			itemType: tab === 'broker' ? 'BROKER_CONTACT' : 'EMPLOYER_CONTACT',
			listId: Number(listId),
		},
		{
			onSuccess: (numberOfContactsThatWereDeleted) => {
				addNotification({
					type: 'success',
					autoClose: false,
					title: `Deleted ${numberOfContactsThatWereDeleted} contacts from list.`,
					message: `Contacts will be removed from ${listName ? `"${listName}"` : 'list'} in a few minutes.`,
				});
			},
			onError: () => {
				addNotification({
					type: 'error',
					title: 'Error deleting contacts from list.',
					message: 'Please try again later.',
				});
			},
		},
	);

	return { deleteSelected: mutate, loading: loading };
};
