import { useCallback } from 'react';
import { useServiceProvidersStore } from '../../../../../ServiceProviders.store';

export const useOnSelect = () => {
	const { setFilters } = useServiceProvidersStore();
	const onSelect = useCallback(
		({ value }) => {
			setFilters.location.add(value);
		},
		[setFilters],
	);

	return onSelect;
};
