import { Avatar, Box, Button, Paper } from '@benefitflow/designsystem';

import { Description } from './components';
import { ProviderCardProps } from './ProviderCard.types';
import { Card } from 'new-beginning/components/shared/Card/Card';
import { useGetValueByScreenSizes } from 'new-beginning/hooks/useGetValueByScreenSizes';

export const ProviderCard = ({ loading, name, onClick, fees, states }: ProviderCardProps) => {
	const getValueByScreenSizes = useGetValueByScreenSizes();

	return (
		<Paper>
			<Box
				testId="card-container"
				display="grid"
				gap="sm"
				style={{
					gridTemplateColumns: 'repeat(12, 1fr)',
				}}
			>
				<Box
					overflow="hidden"
					flexDirection={getValueByScreenSizes({ sm: 'column', md: 'row', lg: 'row' })}
					gridSpan={getValueByScreenSizes({ sm: 12, md: 6, lg: 6 })}
					display="flex"
					justifyContent="start"
					alignItems="center"
					gap="sm"
				>
					<Box onClick={onClick} style={{ cursor: 'pointer' }}>
						<Avatar loading={loading} variant="square" fallbackIcon="business" />
					</Box>
					<Box overflow="hidden" display="flex" justifyContent="start" alignItems="start" flexDirection="column" gap="xxs">
						<Card.Title onClick={onClick} loading={loading}>
							{name}
						</Card.Title>
						<Card.Label data-testid="card-states" size="md" weight="regular" loading={loading}>
							{states}
						</Card.Label>
					</Box>
				</Box>
				<Box overflow="hidden" gridSpan={getValueByScreenSizes({ sm: 12, md: 6, lg: 4 })} display="flex" justifyContent="center" alignItems="center">
					<Description testId="card-fees" label="Fees Reported" value={fees} loading={loading} />
				</Box>
				<Box
					gridSpan={getValueByScreenSizes({ sm: 12, md: 12, lg: 2 })}
					display="flex"
					justifyContent={getValueByScreenSizes({ sm: 'center', md: 'center', lg: 'end' })}
					alignItems="center"
				>
					<Button loading={loading} onClick={onClick}>
						More Info
					</Button>
				</Box>
			</Box>
		</Paper>
	);
};
