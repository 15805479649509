import React from 'react';
import { InfinityList } from '@benefitflow/designsystem';

import { Row } from './components';
import { ContactDTO } from '../../../../../../queries';
import { useScreenSize } from '../../../../../../hooks/useScreenSize';
import { useHeight } from '../../../BrokerContactCardV2/hooks/useHeight';
import { BrokerContactCardV2 } from '../../../BrokerContactCardV2/BrokerContactCardV2';

export const List = ({
	contacts,
	fetchNextPage,
	hasNextPage,
	isFetching,
	isLoading,
	minHeight,
}: {
	contacts: ContactDTO[];
	minHeight: number;
	fetchNextPage: () => void;
	hasNextPage: boolean;
	isFetching: boolean;
	isLoading: boolean;
}) => {
	const screenSize = useScreenSize();
	const rowHeight = useHeight();

	return (
		<InfinityList
			rows={contacts}
			gap={12}
			height={'calc(100vh - 20rem)'}
			minHeight={minHeight}
			getLoaderRow={(index) => <BrokerContactCardV2 key={index} variant="search" loading />}
			getRow={({ data, index }) => <Row key={index} contact={data} />}
			getRowHeight={() => rowHeight}
			screenSize={screenSize}
			fetchNextPage={fetchNextPage}
			hasNextPage={hasNextPage}
			isFetching={isFetching}
			isLoading={isLoading}
		/>
	);
};
