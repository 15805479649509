import { Avatar, Box, Description, Paper } from '@benefitflow/designsystem';

import { Button, Labels } from './components';
import { BrokerDTO } from '../../../../queries/useBrokersQuery';
import { Card } from 'new-beginning/components/shared/Card/Card';
import { useGetValueByScreenSizes } from 'new-beginning/hooks/useGetValueByScreenSizes';

export const BrokerCardV2 = ({
	commissionsValue,
	location,
	moreInfoCallback,
	name,
	numberOfClients,
	numberOfOffices,
	premiumsValue,
	totalContacts,
	website,
	imageSrcs,
	linkedinUrl,
	loading,
}: Partial<BrokerDTO> & { moreInfoCallback?: () => void; loading?: boolean }) => {
	const getValueByScreenSizes = useGetValueByScreenSizes();

	return (
		<Paper>
			<Box testId="card-container" style={{ gridTemplateColumns: 'repeat(12,1fr)' }} gap="sm" width="full" display="grid" flexWrap="wrap">
				<Box overflow="hidden" gridSpan={getValueByScreenSizes({ lg: 5, md: 5, sm: 12 })} display="flex" gap="sm">
					<Box display="flex" justifyContent="center" alignItems="center" style={{ cursor: 'pointer' }} onClick={moreInfoCallback}>
						<Avatar.Multiple loading={loading} variant="square" srcs={imageSrcs} fallbackIcon="business" />
					</Box>
					<Box width="full" overflow="hidden" display="flex" gap="xxs" justifyContent="center" flexDirection="column" height="auto">
						<Card.Title website={website} linkedinUrl={linkedinUrl} loading={loading} onClick={moreInfoCallback}>
							{name}
						</Card.Title>
						<Labels.LocationAndClients location={location} totalContacts={totalContacts} loading={loading} />
					</Box>
				</Box>
				<Box
					overflow="hidden"
					gridSpan={getValueByScreenSizes({ lg: 5, md: 5, sm: 12 })}
					flexDirection={getValueByScreenSizes({ lg: 'row', md: 'column', sm: 'column' })}
					display="flex"
					gap="xs"
					justifyContent="around"
				>
					<Box gap="xs" display="flex" flexDirection="column" height="auto" justifyContent="center">
						<Description icon="switch" label="Commissions" value={commissionsValue} loading={loading} />
						<Description icon="bills" label="Premiums" value={premiumsValue} loading={loading} />
					</Box>
					<Box gap="xs" display="flex" flexDirection="column" height="auto" justifyContent="center">
						<Description icon="briefcase" label="Clients" value={numberOfClients} loading={loading} />
						<Description icon="business" label="Offices" value={numberOfOffices} loading={loading} />
					</Box>
				</Box>
				<Box
					gridSpan={getValueByScreenSizes({ lg: 2, md: 2, sm: 12 })}
					height="auto"
					display="flex"
					justifyContent={getValueByScreenSizes({ lg: 'end', md: 'end', sm: 'center' })}
					alignItems="center"
				>
					<Button onClick={moreInfoCallback} loading={loading} />
				</Box>
			</Box>
		</Paper>
	);
};
