import { useCallback, useContext } from 'react';
import { InfinityList } from '@benefitflow/designsystem';

import { Row } from './components';
import { useHeight } from '../../../EmployerContactCardV2/hooks';
import { useScreenSize } from '../../../../../../hooks/useScreenSize';
import { EmployerContactsContext } from '../../EmployerContacts.context';
import { EmployerContactCardV2 } from '../../../EmployerContactCardV2/EmployerContactCardV2';

export const List = ({ minHeight }: { minHeight?: number }) => {
	const { fetchNextPage, contacts, hasNextPage, isFetching, isLoading } = useContext(EmployerContactsContext);
	const screenSize = useScreenSize();
	const height = useHeight();
	const getRowHeight = useCallback(() => height, [height]);

	return (
		<InfinityList
			screenSize={screenSize}
			fetchNextPage={fetchNextPage}
			getLoaderRow={(index) => <EmployerContactCardV2 key={index} variant="search" loading />}
			getRow={({ data, index }) => <Row key={index} contact={data} />}
			gap={12}
			isLoading={isLoading}
			getRowHeight={getRowHeight}
			hasNextPage={hasNextPage}
			height={'calc(100vh - 20rem)'}
			minHeight={minHeight}
			isFetching={isFetching}
			rows={contacts}
		/>
	);
};
