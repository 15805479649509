import { debounce } from 'lodash-es';
import { Input } from '@benefitflow/designsystem';
import { useEffect, useMemo, useState } from 'react';

const DELAY = 300;

export const SearchBar = ({ setValue, value, placeholder }: { value: string; setValue: (value: string) => void; placeholder: string }) => {
	const [internalValue, setInternalValue] = useState(value);
	const showClearIcon = useMemo(() => !!value, [value]);
	useEffect(() => {
		const debounced = debounce(() => setValue(internalValue), DELAY);
		debounced();
		return debounced.cancel;
	}, [internalValue, setValue]);

	useEffect(() => {
		if (!value) setInternalValue('');
	}, [value]);

	return (
		<Input
			startIcon="search"
			endIcon={showClearIcon ? 'multiply' : undefined}
			placeholder={placeholder}
			onChange={(e) => setInternalValue(e.target.value)}
			onEndIconClick={showClearIcon && (() => setValue(''))}
			value={internalValue}
		/>
	);
};
