import { useRef } from 'react';

import { useContacts, useOptions, useSetBrokerId } from './hooks';
import { Filters, Header, List } from './components';
import { useBrokerContactsDetailsStore } from './Contacts.store';
import { TabLayout } from '../../../../shared/TabLayout/TabLayout';
import { useElementDimensions } from '../../../../../hooks/useElementDimensions';

export const Contacts = () => {
	const { contacts, isFetching: isFetchingContacts, isLoading: isLoadingContacts, hasNextPage, fetchNextPage } = useContacts();
	const { isLoading: isLoadingOptions, options, totalNumberOfContacts } = useOptions();
	const { filters } = useBrokerContactsDetailsStore();
	const sidebarRef = useRef<HTMLDivElement>(null);
	const { height: minHeight } = useElementDimensions({
		ref: sidebarRef,
		dependency: filters,
	});
	useSetBrokerId();

	return (
		<TabLayout
			sidebarRef={sidebarRef}
			list={
				<List
					contacts={contacts}
					fetchNextPage={fetchNextPage}
					hasNextPage={hasNextPage}
					isFetching={isFetchingContacts}
					isLoading={isLoadingContacts}
					minHeight={minHeight}
				/>
			}
			buttons={<Header contacts={contacts} isLoading={isFetchingContacts || isLoadingOptions} totalNumberOfContacts={totalNumberOfContacts} />}
			filters={<Filters isLoading={isLoadingOptions} options={options} />}
		/>
	);
};
