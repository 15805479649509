import { useContext } from 'react';
import { useMutation } from '@tanstack/react-query';

import { APIContext } from 'new-beginning/services/api/Api.context';
import { CreateExportBrokerContactsListParams, CreateExportBrokerContactsParams, ExportApi } from 'new-beginning/services/api/clients/ExportApi';
import { MutationOptions } from 'new-beginning/services/api/types/MutationOptions';

export const useExportMutation = ({
	options,
}: {
	options?: MutationOptions<ExportApi['createExport']> & { params?: CreateExportBrokerContactsParams | CreateExportBrokerContactsListParams };
}) => {
	const { exportApi } = useContext(APIContext);
	const mutation = useMutation({ mutationFn: exportApi.createExport.bind(exportApi) as ExportApi['createExport'], ...options });

	return mutation;
};
