import { Box, Skeleton, Typography } from '@benefitflow/designsystem';
import { useScreenSize } from 'new-beginning/hooks/useScreenSize';

export const Labels = {
	LocationAndClients: ({ location, totalContacts, loading }: { location?: string; totalContacts?: string; loading: boolean }) => {
		const screenSize = useScreenSize();
		if (loading) return <Skeleton height="md" width="xl6" />;
		if (!location && (!totalContacts || totalContacts == '0')) return null;
		return (
			<Box width="full" display="flex" flexDirection={screenSize === 'lg' ? 'row' : 'column'} gap="xs" justifyContent="start" alignItems="start">
				{!!location && (
					<Typography.Text data-testid="location" ellipsis size="sm" weight="regular" whiteSpace="nowrap">
						HQ in {location}
					</Typography.Text>
				)}
				{!!location && screenSize === 'lg' && !!totalContacts && (
					<Typography.Text size="sm" weight="regular" color="neutral-500">
						•
					</Typography.Text>
				)}
				{!!totalContacts && (
					<Typography.Text ellipsis size="sm" weight="regular" color="neutral-500" whiteSpace="nowrap">
						{totalContacts} Contacts
					</Typography.Text>
				)}
			</Box>
		);
	},
};
