import { useCallback, useContext } from 'react';
import { Box, InfinityList } from '@benefitflow/designsystem';

import { LoaderRow, Row } from './components';
import { useContactsListStore } from '../../ContactsList.store';
import { ContactsListContext } from '../../ContactsList.context';
import { useHeight as useBrokerCardHeight } from '../../../Search/BrokerContactCardV2/hooks';
import { useHeight as useEmployerCardHeight } from '../../../Search/EmployerContactCardV2/hooks';
import { useScreenSize } from '../../../../../hooks/useScreenSize';
import { ListType } from 'common.model/src/db/model-decorators/type-extensions';

export const List = ({ minHeight }: { minHeight?: number }) => {
	const { contacts, fetchNextPage, hasNextPage, isFetching, isLoading, listType } = useContext(ContactsListContext);
	const brokerCardHeight = useBrokerCardHeight();
	const employerCardHeight = useEmployerCardHeight();
	const screenSize = useScreenSize();
	const getRowHeight = useCallback(() => {
		if (listType === ListType.BROKER_CONTACT) return brokerCardHeight;
		if (listType === ListType.EMPLOYER_CONTACT) return employerCardHeight;
	}, [listType, brokerCardHeight, employerCardHeight]);

	return (
		<Box mb="sm">
			<InfinityList
				hasNextPage={hasNextPage}
				height={'calc(100vh - 20rem)'}
				isFetching={isFetching}
				isLoading={isLoading}
				gap={12}
				minHeight={minHeight}
				screenSize={screenSize}
				rows={contacts}
				fetchNextPage={fetchNextPage}
				getLoaderRow={(index) => <LoaderRow key={index} />}
				getRow={({ data, index }) => <Row key={index} contact={data} />}
				getRowHeight={getRowHeight}
			/>
		</Box>
	);
};
