import { Box, Skeleton, Typography } from '@benefitflow/designsystem';

export const Description = ({ label, value, loading, testId }: { label?: string; value?: string; loading?: boolean; testId?: string }) => {
	if (loading)
		return (
			<Box display="flex" flexDirection="column" gap="sm" alignItems="center" justifyContent="center">
				<Skeleton height="md" width="xl4" />
				<Skeleton height="md" width="xl3" />
			</Box>
		);
	if (!value) return null;

	return (
		<Box display="flex" flexDirection="column" gap="xs" justifyContent="center" alignItems="center">
			<Typography.Text size="sm" weight="regular" color="neutral-400">
				{label}
			</Typography.Text>
			<Typography.Text data-testid={testId} size="sm" weight="regular">
				{value}
			</Typography.Text>
		</Box>
	);
};
