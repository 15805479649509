import { useCallback, useContext, useMemo } from 'react';
import { Box, Checkbox, Skeleton, Typography } from '@benefitflow/designsystem';

import { useContactsListStore } from '../../../../ContactsList.store';
import { ContactsListContext } from '../../../../ContactsList.context';
import { FormattingUtils } from '../../../../../../../../ui-components';
import { ListType } from 'common.model/src/db/model-decorators/type-extensions';

export const RecordsCounter = () => {
	const { checkMode, toggleCheckMode } = useContactsListStore();
	const { totalNumberOfContacts, numberOfSelectedContacts, isLoadingFilters, contacts, listType } = useContext(ContactsListContext);
	const isSelectAll = useMemo(() => checkMode === 'select-all', [checkMode]);
	const onChange = useCallback(() => toggleCheckMode(contacts), [toggleCheckMode, contacts]);
	const contactTypeLabel = useMemo(() => (listType === ListType.BROKER_CONTACT ? 'Broker' : 'Employer'), [listType]);
	if (isLoadingFilters) return <Skeleton width="xl7" height="sm" containerBackground="gray" />;

	return (
		<Box display="flex" alignItems="center" gap="xs">
			<Checkbox checked={isSelectAll} onChange={onChange} />
			<Typography.Text whiteSpace="nowrap" color="neutral-500">
				{FormattingUtils.formatCommas(numberOfSelectedContacts)} Selected
			</Typography.Text>
			<Typography.Text whiteSpace="nowrap" color="neutral-500">
				/ {FormattingUtils.formatCommas(totalNumberOfContacts)} {contactTypeLabel} Contacts
			</Typography.Text>
		</Box>
	);
};
