import { BrokerContactFilters } from 'common.model/src/types/common/search/filters/ContactFilters';
import { APIClient } from '../types';
import { BrokerContactsFiltersBreakdownResponse } from 'common.model/src/types/common/search/Breakdowns';
import { EntityLocation } from 'common.model/src/types/master/location';
import { ContactSearchResponse } from 'common.model/src/types/common/contact/ContactSearchResponse';
import { GetContactResponse } from 'common.model/src/types/common/contact/GetContactResponse';
import { GetContactRequest } from 'common.model/src/types/common/contact/GetContactRequest';
import { ApiBase, IApiResponse, IMutationResponse } from '../ApiBase';
import {
	AddSelectedToExistingListRequest,
	AddSelectedToExistingListResponse,
	AddSelectedToNewListRequest,
	AddSelectedToNewListResponse,
	CompanyInfoSearchRequest,
	CompanyInfoSearchResponse,
	CompanySearchRequest,
	CompanySearchResponse,
} from 'common.model/src/types/services/broker-contact-service/BrokerContactService';
import { BulkGetContactRequest } from 'common.model/src/types/common/contact/BulkGetContactRequest';
import { BulkGetContactResponse } from 'common.model/src/types/common/contact/BulkGetContactResponse';
import { MutationOptions, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';

export interface IBrokerContactApi {
	useSearch: (req: BrokerContactFilters) => Promise<ContactSearchResponse>;
	useSearchResultsBreakdown: (req: BrokerContactFilters) => IApiResponse<BrokerContactsFiltersBreakdownResponse>;
	useAddSelectedToExistingList: (
		options?: UseMutationOptions<AddSelectedToExistingListResponse, unknown, AddSelectedToExistingListRequest>,
	) => UseMutationResult<AddSelectedToExistingListResponse>;
	useAddSelectedToNewList: (
		options?: UseMutationOptions<AddSelectedToNewListResponse, unknown, AddSelectedToNewListRequest>,
	) => UseMutationResult<AddSelectedToNewListResponse>;
	useCompanySearch: (req: CompanySearchRequest) => IApiResponse<CompanySearchResponse>;
	useContactLocationSearch: (req: CompanySearchRequest) => IApiResponse<EntityLocation[]>;
	useGetCompanyInfo: (req: CompanyInfoSearchRequest) => IApiResponse<CompanyInfoSearchResponse>;
	useGetContact: (req: GetContactRequest, options?: MutationOptions<GetContactResponse>) => IMutationResponse<GetContactResponse>;
	useBulkSelectAllGetContact: (
		options?: UseMutationOptions<BulkGetContactResponse, unknown, BulkGetContactRequest<BrokerContactFilters>>,
	) => UseMutationResult<BulkGetContactResponse>;
}

export class BrokerContactApi extends ApiBase implements IBrokerContactApi {
	constructor(
		readonly client: APIClient,
		loggedInUser: string,
	) {
		super(client, loggedInUser, 'brokerContacts');
	}

	// Note: Used in useInfiniteQuery - cant have a hook in a hook
	useSearch = async (req: BrokerContactFilters): Promise<ContactSearchResponse> => this.post('search', req);

	useSearchResultsBreakdown = (req: BrokerContactFilters): IApiResponse<BrokerContactsFiltersBreakdownResponse> =>
		this.callAuthenticated('searchResultsBreakdown', req);

	useAddSelectedToExistingList = (
		options?: UseMutationOptions<AddSelectedToExistingListResponse, unknown, AddSelectedToExistingListRequest>,
	): UseMutationResult<AddSelectedToExistingListResponse> => this.mutate('addSelectedToExistingList', options);

	useAddSelectedToNewList = (
		options?: UseMutationOptions<AddSelectedToNewListResponse, unknown, AddSelectedToNewListRequest>,
	): UseMutationResult<AddSelectedToNewListResponse, unknown, AddSelectedToNewListRequest> => this.mutate('addSelectedToNewList', options);

	useCompanySearch = (req: CompanySearchRequest): IApiResponse<CompanySearchResponse> => this.callAuthenticated('companySearch', req);

	useContactLocationSearch = (req: CompanySearchRequest): IApiResponse<EntityLocation[]> => this.callAuthenticated('contactLocationSearch', req);

	useGetCompanyInfo = (req: CompanyInfoSearchRequest): IApiResponse<CompanyInfoSearchResponse> => this.callAuthenticated('getCompanyInfo', req);

	useGetContact = (req: GetContactRequest, options?: MutationOptions<GetContactResponse>): IMutationResponse<GetContactResponse> =>
		this.mutateAuthenticated('getContact', req, options);

	useBulkSelectAllGetContact = (
		options?: UseMutationOptions<BulkGetContactResponse, unknown, BulkGetContactRequest<BrokerContactFilters>>,
	): UseMutationResult<BulkGetContactResponse> => this.mutate('bulkSelectAllGetContact', options);
}
