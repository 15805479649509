import { useContext, useMemo, useRef } from 'react';

import { useBrokersStore } from './Brokers.store';
import { Filters, Header, List } from './components';
import { SearchBar } from '../components';
import { TabLayout } from '../../../shared/TabLayout/TabLayout';
import { APIContext } from '../../../../services/api/Api.context';
import { useBrokersQuery } from '../../../../queries/useBrokersQuery';
import { useElementDimensions } from '../../../../hooks/useElementDimensions';
import { ClearbitFooter } from '../../../shared/ClearbitFooter/ClearbitFooter';

export const Brokers = () => {
	const sidebarRef = useRef(null);
	const { filters, setFilter } = useBrokersStore();
	const { brokerApi } = useContext(APIContext);
	const {
		data: filterOptions,
		query: { isLoading: filtersAreLoading },
	} = brokerApi.useSearchResultsBreakdown(filters);
	const {
		data: pages,
		isLoading,
		fetchNextPage,
		hasNextPage,
		isFetching,
	} = useBrokersQuery({
		filters,
		options: {
			getNextPageParam: (lastPage) => {
				if (!lastPage?.data?.length) return null;
				return lastPage.page + 1;
			},
		},
	});
	const brokers = useMemo(() => pages?.pages?.flatMap((page) => page.data) ?? [], [pages]);
	const { height: sidebarHeight } = useElementDimensions({ ref: sidebarRef, dependency: filters });

	return (
		<TabLayout
			sidebarRef={sidebarRef}
			filters={<Filters isLoading={filtersAreLoading} options={filterOptions?.breakdowns} />}
			search={<SearchBar placeholder="Search benefit brokers" value={filters.masterBrokerNameSearch} setValue={setFilter.masterBrokerNameSearch} />}
			buttons={<Header resultsCount={filterOptions?.totalHits} isLoading={isLoading || filtersAreLoading} />}
			list={
				<List
					minHeight={sidebarHeight}
					brokers={brokers}
					hasNextPage={hasNextPage}
					isFetching={isFetching}
					isLoading={isLoading}
					fetchNextPage={fetchNextPage}
				/>
			}
			footer={<ClearbitFooter />}
		/>
	);
};
