import { FC } from 'react';
import cn from 'classnames';
import { IoShuffle } from 'react-icons/io5';
import { List } from '../../lib/entities/list';
import { Icon } from '@benefitflow/designsystem';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'atoms/Link';

interface ListRowProps {
	handleDelete: VoidFunction;
	handleSync: VoidFunction;
	handleSelect: VoidFunction;
	listData: List;
	isSyncDisabled?: boolean;
	isDeleteDisabled?: boolean;
}

export const ListRow: FC<ListRowProps> = ({ handleSync, listData, handleDelete, handleSelect, isDeleteDisabled, isSyncDisabled }) => {
	return (
		<tr>
			<td
				className={cn('right-border', 'text-center')}
				onClick={(e) => {
					e.stopPropagation();
				}}
				valign='middle'
			>
				<div className={cn('d-flex', 'gap-2')}>
					{listData.id > 0 && (
						<IconButton onClick={handleSync} disabled={isSyncDisabled} className={cn('bf-icon', 'ic-action')}>
							<IoShuffle size={20} />
						</IconButton>
					)}
					{listData.id > 0 && (
						<IconButton onClick={handleDelete} disabled={isDeleteDisabled} className={cn('bf-icon', 'ic-action')}>
							<Icon name='trash' size='lg' />
						</IconButton>
					)}
				</div>
			</td>
			<td className={cn('text-left', 'txt-no-wrap')} valign='middle'>
				<Link onClick={handleSelect}>
					<p className={cn('bf-txt', 'bf-txt-ellipsis', 'bf-list-row-name')}>{listData.getCuratedName}</p>
				</Link>
			</td>
			<td className={cn('text-right', 'right-border', 'text-align-right')} valign='middle'>
				<p className={cn('bf-list-details')}>{listData.getFormatedContacts}</p>
			</td>
			<td className={cn('txt-nowrap', 'right-border', 'text-align-right')} valign='middle'>
				<p className={cn('bf-list-details')}>{listData.createdBy}</p>
			</td>
			<td className={cn('text-align-right')} valign='middle'>
				<p className={cn('bf-list-details')}>{listData.getLocaleCreatedDate}</p>
			</td>
		</tr>
	);
};
