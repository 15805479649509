import { useCallback, useContext } from 'react';

import { useBrokersStore } from '../../../Brokers.store';
import { ExportContext } from '../../../../../../../context/ExportContext';

export const useExportBrokers = () => {
	const { filters } = useBrokersStore();
	const { onExport } = useContext(ExportContext);
	const exportBrokers = useCallback(() => onExport({ type: 'broker', filters }), [onExport, filters]);

	return exportBrokers;
};
