import { Skeleton, Button as DSButton } from '@benefitflow/designsystem';

export const Button = ({ onClick, loading }: { onClick: () => void; loading?: boolean }) => {
	if (loading) return <Skeleton height="xl2" width="xl4" />;

	return (
		<DSButton testId="more-info-button" size="md" onClick={onClick}>
			More Info
		</DSButton>
	);
};
