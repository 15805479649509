import { Box, Select, Typography } from '@benefitflow/designsystem';
import { useBrokersStore } from '../../../../../../Search/Brokers/Brokers.store';

export const GroupBySelector = () => {
	const { filters, setFilter } = useBrokersStore();

	return (
		<Box display="flex" gap="sm" justifyContent="center" alignItems="center">
			<Typography.Text whiteSpace="nowrap" size="sm" color="neutral-500">
				Group Clients By
			</Typography.Text>
			<Select style={{ width: '11rem' }} value={filters.groupBy} onChange={(e) => setFilter.groupBy(e.target.value)}>
				<Select.Option value="Closest Broker Office">Closest Broker Office</Select.Option>
				<Select.Option value="Reported Broker Office">Reported Broker Office</Select.Option>
			</Select>
		</Box>
	);
};
