import { useMemo } from 'react';
import { useServiceProvidersStore } from '../ServiceProviders.store';
import { useServiceProvidersQuery } from '../../../../../../queries/useServiceProvidersQuery';

export const useServiceProviders = () => {
	const { filters } = useServiceProvidersStore();
	const { data, isLoading, fetchNextPage, hasNextPage, isFetching } = useServiceProvidersQuery({
		options: {
			getNextPageParam: (lastPage) => {
				if (!lastPage?.data?.length) return null;
				return lastPage.page + 1;
			},
		},
		...filters,
	});
	const serviceProviders = useMemo(() => data?.pages?.flatMap((page) => page.data) ?? [], [data]);

	return { serviceProviders, isLoading, fetchNextPage, hasNextPage, isFetching };
};
