import { addNotification } from '@benefitflow/designsystem';
import { MutableRefObject, useCallback, useContext, useMemo } from 'react';
import { GetContactResponse } from 'common.model/src/types/common/contact/GetContactResponse';

import { ContactDTO } from 'new-beginning/queries';
import { APIContext } from 'new-beginning/services/api/Api.context';
import { useBrokerContactsStore } from '../../../../BrokerContacts.store';
import { Notifications } from '../../../../../../../../constants/notifications';
import { useOnPurchaseContactSuccess } from 'new-beginning/hooks/useOnPurchaseContactSuccess';
import { onBrokerClickCallback } from 'new-beginning/utilities/ContactUtils/onBrokerClickCallback';
import { isContactPurchased } from '../../../../../../../../utilities/ContactUtils/isContactPurchased';
import { isContactPurchasable } from '../../../../../../../../utilities/ContactUtils/isContactPurchasable';
import { BrokerContactCardV2 as BrokerContactCard } from 'new-beginning/components/pages/Search/BrokerContactCardV2/BrokerContactCardV2';

export const Row = ({ contact, scrollObserverRef }: { contact: ContactDTO; scrollObserverRef?: MutableRefObject<HTMLDivElement> }) => {
	const { brokerContactApi } = useContext(APIContext);
	const { checkedMap, toggleCheckMap, addPurchasedContact } = useBrokerContactsStore();
	const onSuccess = useOnPurchaseContactSuccess({
		callback: (data: GetContactResponse) => {
			addPurchasedContact({
				id: data?.res.profile_url,
				email: data?.res?.work_email,
				phone: data?.res?.mobile_phone_1,
			});
		},
	});
	const onError = (error: { message?: string }) => {
		addNotification({ type: 'error', message: error?.message ?? Notifications.ContactPurchase.Error.message });
		onError;
	};
	const { mutate, loading } = brokerContactApi.useGetContact({ profile_url: contact?.id }, { onSuccess, onError });
	const onBrokerClick = useCallback(() => onBrokerClickCallback(contact), [contact]);
	const purchasable = useMemo(() => isContactPurchasable(contact), [contact]);
	const purchased = useMemo(() => isContactPurchased(contact), [contact]);
	const checked = useMemo(() => checkedMap[contact?.id] ?? false, [contact, checkedMap]);

	return (
		<div ref={scrollObserverRef}>
			<BrokerContactCard
				checked={checked}
				toggle={() => toggleCheckMap(contact.id)}
				purchasable={purchasable}
				purchased={purchased}
				variant="search"
				onBrokerClick={onBrokerClick}
				onGetContact={() => mutate(contact.id)}
				gettingContact={loading}
				{...contact}
			/>
		</div>
	);
};
