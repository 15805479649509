import { useMemo } from 'react';

import { useEmployersStore } from '../Employers.store';
import { useEmployersQuery } from '../../../../../queries/useEmployersQuery';

export const useEmployers = () => {
	const { filters } = useEmployersStore();
	const { data, isLoading, isFetching, fetchNextPage, hasNextPage } = useEmployersQuery({
		filters,
		options: {
			getNextPageParam: (lastPage) => {
				if (!lastPage?.data?.length) return null;
				return lastPage.page + 1;
			},
		},
	});
	const employers = useMemo(() => data?.pages?.flatMap((page) => page.data) ?? [], [data]);

	return { employers, isLoading, isFetching, fetchNextPage, hasNextPage };
};
