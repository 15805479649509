import { useNavigate } from 'react-router-dom';
import { InfinityList } from '@benefitflow/designsystem';

import { useHeight } from '../../../../ProviderCard/hooks';
import { ProviderCard } from '../../../../ProviderCard/ProviderCard';
import { useScreenSize } from '../../../../../../../hooks/useScreenSize';
import { ServiceProviderDTO } from '../../../../../../../queries/useServiceProvidersQuery';

export const List = ({
	isLoading,
	isFetching,
	serviceProviders,
	fetchNextPage,
	hasNextPage,
	minHeight,
}: {
	fetchNextPage: () => void;
	isFetching: boolean;
	serviceProviders: ServiceProviderDTO[];
	isLoading: boolean;
	hasNextPage: boolean;
	minHeight: number;
}) => {
	const screenSize = useScreenSize();
	const height = useHeight();
	const navigate = useNavigate();

	return (
		<InfinityList
			fetchNextPage={fetchNextPage}
			getLoaderRow={(index) => <ProviderCard key={index} loading />}
			getRow={({ data }) => <ProviderCard key={data.id} {...data} onClick={() => navigate(`/Provider?providerId=${data.id}&providerName=${data.name}`)} />}
			getRowHeight={() => height}
			hasNextPage={hasNextPage}
			height={'70vh'}
			gap={16}
			minHeight={minHeight}
			screenSize={screenSize}
			rows={serviceProviders}
			isFetching={isFetching}
			isLoading={isLoading}
		/>
	);
};
