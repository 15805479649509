import { useNavigate } from 'react-router-dom';
import { InfinityList } from '@benefitflow/designsystem';

import { useHeight } from '../../../BrokerCardV2/hooks';
import { BrokerCardV2 } from '../../../BrokerCardV2/BrokerCardV2';
import { useScreenSize } from '../../../../../../hooks/useScreenSize';
import { BrokerDTO } from '../../../../../../queries/useBrokersQuery';

export const List = ({
	fetchNextPage,
	brokers,
	isLoading,
	isFetching,
	hasNextPage,
	minHeight,
}: {
	fetchNextPage: () => void;
	brokers: BrokerDTO[];
	isLoading: boolean;
	isFetching: boolean;
	hasNextPage: boolean;
	minHeight?: number;
}) => {
	const screenSize = useScreenSize();
	const height = useHeight();
	const navigate = useNavigate();

	return (
		<InfinityList
			fetchNextPage={fetchNextPage}
			getLoaderRow={(index) => <BrokerCardV2 key={index} loading />}
			getRow={({ data, index }) => <BrokerCardV2 moreInfoCallback={() => navigate(`/broker?brokerId=${data.id}`)} key={index} {...data} />}
			getRowHeight={() => height}
			hasNextPage={hasNextPage}
			height={'calc(100vh - 22rem)'}
			isFetching={isFetching}
			isLoading={isLoading}
			rows={brokers}
			gap={12}
			screenSize={screenSize}
			minHeight={minHeight}
		/>
	);
};
