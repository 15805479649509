import { useContext } from 'react';

import { APIContext } from '../../../../../../services/api/Api.context';
import { useBrokerContactsDetailsStore } from '../Contacts.store';
import { useSearchParams } from 'react-router-dom';

export const useOptions = () => {
	const { filters } = useBrokerContactsDetailsStore();
	const { brokerContactApi } = useContext(APIContext);
	const [searchParams] = useSearchParams();
	const {
		data,
		query: { isLoading },
	} = brokerContactApi.useSearchResultsBreakdown({ ...filters, companyMasterIds: [searchParams.get('brokerId')] });

	return {
		isLoading,
		options: data?.breakdowns,
		totalNumberOfContacts: data?.totalHits,
	};
};
