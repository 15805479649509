import { useCallback, useContext, useMemo, useState } from 'react';

import { LocationUtils } from '../../../../../../../utilities';
import { APIContext } from '../../../../../../../services/api/Api.context';

export const useContactLocation = () => {
	const { brokerContactApi } = useContext(APIContext);
	const [search, setSearch] = useState('');
	const {
		data,
		query: { isLoading },
	} = brokerContactApi.useContactLocationSearch({ search, page: 0 });
	const onSearch = useCallback((value: string) => setSearch(value), [setSearch]);
	const options = useMemo(() => data?.map(LocationUtils.parseLocation) ?? [], [data]);

	return { options, isLoading, onSearch };
};
