import { useContext, useMemo, useState } from 'react';

import { useBrokersStore } from '../../../Brokers.store';
import { APIContext } from '../../../../../../../services/api/Api.context';
import { LocationUtils } from '../../../../../../../utilities';

export const useBrokerOffices = () => {
	const [search, setSearch] = useState('');
	const { filters } = useBrokersStore();
	const { brokerApi } = useContext(APIContext);
	const {
		data,
		query: { isLoading },
	} = brokerApi.useSearchBrokerOffices.bind(brokerApi)({ ...filters, brokerOfficeSearch: search });
	const options = useMemo(() => data?.map(LocationUtils.parseLocation) ?? [], [data]);

	return {
		isLoading,
		onSearch: setSearch,
		options,
	};
};
