import { BrokerContactFilters, EmployerContactFilters } from 'common.model/src/types/common/search/filters/ContactFilters';
import { ContactDTO } from '../../../queries';
import { ContactSelectorMethods } from '../../../utilities/StoreUtils/createContactSelectorMethods';
import { ListType } from 'common.model/src/db/model-decorators/type-extensions';

export enum Tabs {
	BROKER_CONTACTS = 'broker',
	EMPLOYER_CONTACTS = 'employer',
}

export interface ContactsListStore extends ContactSelectorMethods {
	tab: `${Tabs}`;
	setTab: (tab: `${Tabs}`) => void;
	setState: (state: Partial<ContactsListStore>) => void;
}

export interface ContactsListContext {
	filters: EmployerContactFilters | BrokerContactFilters;
	contacts: ContactDTO[];
	listType: ListType;
	fetchNextPage: () => void;
	isFetching: boolean;
	hasNextPage: boolean;
	isLoading: boolean;
	isLoadingFilters: boolean;
	totalNumberOfContacts: number;
	numberOfSelectedContacts: number;
}
