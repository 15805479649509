import { BrokerOffice } from 'common.services/src/search/BrokerElasticSearchCache';

export enum BrokerProfileTabs {
	CONTACTS = 'contacts',
	CLIENTS = 'clients',
}

export interface BrokerOfficeDTO {
	name: string;
	comissions: string;
	premiums: string;
	numberOfClients: string;
	location: BrokerOffice['location'];
}

export interface BrokerProfileStore {
	tab: BrokerProfileTabs;
	setTab: (tab: BrokerProfileTabs) => void;
	clients: {
		rowExpandMap: {
			map: Record<string, boolean>;
			set: (rowId: string, value: boolean) => void;
			toggle: (rowId: string) => void;
			clear: () => void;
		};
	};
}
