import { useCallback } from 'react';
import { LocationType } from 'common.model/src/types/master/location';
import { BrokerOffice } from 'common.services/src/search/BrokerElasticSearchCache';

import { BrokerProfileTabs } from '../../../../../../BrokerProfile.types';
import { useBrokerProfileStore } from '../../../../../../BrokerProfile.store';
import { useBrokerContactsDetailsStore } from '../../../../../Contacts/Contacts.store';

export const useOnSeeContactsCallback = () => {
	const { setTab } = useBrokerProfileStore();
	const { setFilters, clearFilters } = useBrokerContactsDetailsStore();

	const onSeeContactsCallback = useCallback(
		(location: BrokerOffice['location']) => () => {
			setTab(BrokerProfileTabs.CONTACTS);
			clearFilters();
			setFilters.brokerOfficeLocations.include.add({
				location: {
					city: location.city,
					locationType: LocationType.CityState,
					searchValue: location.cityStateSearch,
					state: location.longState,
					stateCode: location.state,
				},
			});
		},
		[setTab, clearFilters, setFilters],
	);

	return onSeeContactsCallback;
};
