import { useBrokersStore } from '../../Brokers.store';
import { LOCATION_INPUT_PLACEHOLDER } from '../../../../../../../app/constants';
import { Filters as FiltersComponent } from '../../../../../shared/Filters/Filters';
import { useBrokerOffices, useEmployeeCount, useEmployerHeadquarters, useRenewalDate } from './hooks';
import { BreakdownResponse } from 'common.model/src/types/common/search/Breakdowns';

export const Filters = ({
	isLoading,

	options,
}: {
	isLoading: boolean;
	options: BreakdownResponse['breakdowns'];
}) => {
	const { filters, setFilter, filtersVisibilityMap, resetFiltersVisibility, toggleFilterVisibility, clearFilters } = useBrokersStore();
	const brokerOffices = useBrokerOffices();
	const renewalDate = useRenewalDate();
	const employeeCount = useEmployeeCount();
	const employerHeadquarters = useEmployerHeadquarters();

	return (
		<FiltersComponent
			onClear={() => {
				resetFiltersVisibility();
				clearFilters();
			}}
		>
			<FiltersComponent.Section title="Broker Attributes" />
			<FiltersComponent.LocationFilter
				title="Office Location"
				placeholder={LOCATION_INPUT_PLACEHOLDER}
				description="Office locations in [California]"
				icon="location"
				isOpen={filtersVisibilityMap.brokerOfficeLocations}
				onToggle={toggleFilterVisibility.brokerOfficeLocations}
				setRadiusMiles={setFilter.brokerOfficeLocations.setRadiusMiles}
				testId=""
				onDelete={({ type, value }) => setFilter?.brokerOfficeLocations?.[type]?.remove({ searchValue: value })}
				onSearch={brokerOffices.onSearch}
				onSelect={({ type, value }) => setFilter?.brokerOfficeLocations?.[type]?.add(value)}
				options={brokerOffices.options}
				isLoading={brokerOffices.isLoading}
				selectedOptions={filters.brokerOfficeLocations}
			/>
			<FiltersComponent.IncludeExcludeFilter
				title="Broker Size"
				description="Brokers with [national] presence"
				icon="bars"
				isLoading={isLoading}
				isOpen={filtersVisibilityMap.brokerSize}
				onDelete={({ type, value }) => setFilter?.brokerSize?.[type].remove(value)}
				onSelect={({ type, value }) => setFilter?.brokerSize?.[type].add(value)}
				onToggle={toggleFilterVisibility.brokerSize}
				options={options?.brokerSize}
				placeholder="e.g. National or Regional"
				selectedOptions={filters?.brokerSize}
				testId=""
			/>
			<FiltersComponent.CheckboxFilter
				title="Primary Broker"
				description="Acts as primary broker with at least 1 client"
				icon="verified-fill"
				option="Primary Broker"
				checked={filters.filterPrimaryBroker}
				setChecked={setFilter.filterPrimaryBroker.set}
				toggleOpen={toggleFilterVisibility.filterPrimaryBroker}
				isOpen={filtersVisibilityMap.filterPrimaryBroker}
			/>
			<FiltersComponent.Section title="Client Attributes" />
			<FiltersComponent.CustomRangeFilter
				title="Employee Count"
				defaultOption={{ start: '0', end: '3000000' }}
				description="Clients with [0 - 10k] employees"
				icon="users"
				isLoading={isLoading}
				isOpen={filtersVisibilityMap.participantRanges}
				onSelect={setFilter.participantRanges}
				onToggle={toggleFilterVisibility.participantRanges}
				options={employeeCount.options}
				selectedRange={filters.participantRanges}
				testId=""
			/>
			<FiltersComponent.LocationFilter
				title="HQ Location"
				description={`Clients located in [California]`}
				icon="location"
				placeholder={LOCATION_INPUT_PLACEHOLDER}
				isLoading={employerHeadquarters.isLoading}
				setRadiusMiles={setFilter.employerHQLocations.setRadiusMiles}
				isOpen={filtersVisibilityMap.employerHQLocations}
				onDelete={({ type, value }) => setFilter.employerHQLocations?.[type]?.remove({ searchValue: value })}
				onSearch={employerHeadquarters.onSearch}
				onSelect={({ type, value }) => setFilter.employerHQLocations?.[type].add(value)}
				onToggle={toggleFilterVisibility.employerHQLocations}
				options={employerHeadquarters.options}
				selectedOptions={filters.employerHQLocations}
				testId=""
			/>
			<FiltersComponent.IncludeExcludeFilter
				title="Industry"
				description="Clients in the [utilities] industry"
				icon="briefcase"
				isLoading={isLoading}
				isOpen={filtersVisibilityMap.industries}
				onDelete={({ type, value }) => setFilter?.industries?.[type]?.remove(value)}
				onSelect={({ type, value }) => setFilter?.industries?.[type]?.add(value)}
				onToggle={toggleFilterVisibility?.industries}
				options={options?.industries}
				placeholder="e.g. Information or Utilities"
				selectedOptions={filters?.industries}
				testId=""
			/>
			<FiltersComponent.IncludeExcludeFilter
				title="Employer Type"
				description="Clients with [public] employer type"
				icon="sitemap"
				isLoading={isLoading}
				isOpen={filtersVisibilityMap.entityTypes}
				onDelete={({ type, value }) => setFilter?.entityTypes?.[type]?.remove(value)}
				onSelect={({ type, value }) => setFilter?.entityTypes?.[type]?.add(value)}
				onToggle={toggleFilterVisibility?.entityTypes}
				options={options?.entityTypes}
				placeholder="e.g. Public or Private"
				selectedOptions={filters?.entityTypes}
				testId=""
			/>
			<FiltersComponent.IncludeExcludeFilter
				title="Funding"
				description="Clients with [fully insured] funding"
				icon="bills"
				isLoading={isLoading}
				isOpen={filtersVisibilityMap.planTypes}
				onDelete={({ type, value }) => setFilter?.planTypes?.[type]?.remove(value)}
				onSelect={({ type, value }) => setFilter?.planTypes?.[type]?.add(value)}
				onToggle={toggleFilterVisibility?.planTypes}
				options={options?.planTypes}
				placeholder="e.g. Fully Insured or Self Insured"
				selectedOptions={filters?.planTypes}
				testId=""
			/>
			<FiltersComponent.IncludeExcludeFilter
				title="Insurance Products"
				description="Clients with [medical] insurance"
				icon="shield"
				isLoading={isLoading}
				isOpen={filtersVisibilityMap.lineOfBusiness}
				onDelete={({ type, value }) => setFilter?.lineOfBusiness?.[type]?.remove(value)}
				onSelect={({ type, value }) => setFilter?.lineOfBusiness?.[type]?.add(value)}
				onToggle={toggleFilterVisibility?.lineOfBusiness}
				options={options?.lineOfBusiness}
				placeholder="e.g. Medical or Dental"
				selectedOptions={filters?.lineOfBusiness}
				testId=""
			/>
			<FiltersComponent.IncludeExcludeFilter
				title="Carrier"
				description="Clients with [Aetna] carrier"
				icon="business"
				isLoading={isLoading}
				isOpen={filtersVisibilityMap.carrierName}
				onDelete={({ type, value }) => setFilter?.carrierName?.[type]?.remove(value)}
				onSelect={({ type, value }) => setFilter?.carrierName?.[type]?.add(value)}
				onToggle={toggleFilterVisibility?.carrierName}
				options={options?.carrierName}
				placeholder="e.g. Aetna or UnitedHealthcare"
				selectedOptions={filters?.carrierName}
				testId=""
			/>
			<FiltersComponent.RangeFilter
				title="Renewal Date"
				description="Clients with [July] renewals"
				icon="calendar"
				isLoading={isLoading}
				isOpen={filtersVisibilityMap.renewalDatesRanges}
				onSelect={setFilter.renewalDatesRanges}
				onToggle={toggleFilterVisibility.renewalDatesRanges}
				selectedRange={filters.renewalDatesRanges}
				testId=""
				defaultOption={{ start: '1', end: '12' }}
				options={renewalDate.options}
			/>
			<FiltersComponent.IncludeExcludeFilter
				title="PEO"
				description="Clients with [ADP] PEO"
				icon="peo"
				isLoading={isLoading}
				isOpen={filtersVisibilityMap.employerPEOName}
				onDelete={({ type, value }) => setFilter.employerPEOName?.[type].remove(value)}
				onSelect={({ type, value }) => setFilter.employerPEOName?.[type].add(value)}
				onToggle={toggleFilterVisibility?.employerPEOName}
				options={options?.employerPEOName}
				placeholder="e.g. ADP or TriNet"
				allOptionsLabel="Any PEO"
				selectedOptions={filters?.employerPEOName}
				testId=""
			/>
			<FiltersComponent.IncludeExcludeFilter
				title="Accounting Firm"
				description="Clients that use [KPMG] for accounting"
				icon="calculator"
				isLoading={isLoading}
				isOpen={filtersVisibilityMap.employerAccountantFirmName}
				onDelete={({ type, value }) => setFilter.employerAccountantFirmName?.[type].remove(value)}
				onSelect={({ type, value }) => setFilter.employerAccountantFirmName?.[type].add(value)}
				onToggle={toggleFilterVisibility?.employerAccountantFirmName}
				options={options?.employerAccountantFirmName}
				placeholder="e.g. KPMG or Deloitte"
				allOptionsLabel="Any Accounting Firm"
				selectedOptions={filters?.employerAccountantFirmName}
				testId=""
			/>
		</FiltersComponent>
	);
};
