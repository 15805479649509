import { useMemo } from 'react';

import { Options } from '../Location.constants';
import { useServiceProvidersStore } from '../../../../../ServiceProviders.store';

export const useValues = () => {
	const {
		filters: { location },
	} = useServiceProvidersStore();
	const values = useMemo(
		() =>
			location?.map((state) => ({
				label: (() => {
					const { label, value } = Options.find(({ value }) => value == state);
					return `${label} (${value})`;
				})(),
				value: state,
			})) ?? [],
		[location],
	);

	return values;
};
