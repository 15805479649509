import { createContext } from 'react';
import { BrokerFilters, EmployerFilters } from 'common.model/src/types/common/search/filters/CompanyFilters';
import { BrokerContactFilters, EmployerContactFilters } from 'common.model/src/types/common/search/filters/ContactFilters';

export type OnExportParameters =
	| {
			type: 'brokerContacts';
			params: {
				filters: BrokerContactFilters;
				checkedMap: Record<string, boolean>;
				notCheckedMap: Record<string, boolean>;
			};
	  }
	| {
			type: 'brokerContactsList';
			params: {
				filters: BrokerContactFilters;
				checkedMap: Record<string, boolean>;
				notCheckedMap: Record<string, boolean>;
				isSelecteAll: boolean;
				listId: string;
			};
	  }
	| {
			type: 'employerContactsList';
			params: {
				checkedMap: Record<string, boolean>;
				isSelecteAll: boolean;
				notCheckedMap: Record<string, boolean>;
				filters: EmployerContactFilters;
				listId: string;
			};
	  }
	| {
			type: 'broker';
			filters: BrokerFilters;
	  }
	| {
			type: 'brokerOffice';
			filters: BrokerFilters;
	  }
	| {
			type: 'employerContacts';
			filters: EmployerContactFilters;
			checkedMap: Record<string, boolean>;
			notCheckedMap: Record<string, boolean>;
	  }
	| {
			type: 'employer';
			filters: EmployerFilters;
	  }
	| {
			type: 'policy';
			filters: EmployerFilters;
	  }
	| {
			type: 'policy';
			filters: BrokerFilters;
	  };

export interface ExportContextType {
	onExport: (params: OnExportParameters) => void;
	isExporting: boolean;
}

export const ExportContext = createContext<ExportContextType>({
	onExport: () => {},
	isExporting: false,
} as ExportContextType);
