import { useContext, useMemo, useState } from 'react';

import { useEmployersStore } from '../../../Employers.store';
import { LocationUtils } from '../../../../../../../utilities';
import { APIContext } from '../../../../../../../services/api/Api.context';

export const usePrimaryBrokerOfficeLocations = () => {
	const [search, setSearch] = useState('');
	const { filters } = useEmployersStore();
	const {
		brokerApi: { useSearchBrokerOffices },
	} = useContext(APIContext);
	const {
		data,
		query: { isLoading },
	} = useSearchBrokerOffices({ ...filters, brokerOfficeSearch: search });
	const options = useMemo(() => data?.map(LocationUtils.parseLocation) ?? [], [data]);

	return {
		options,
		isLoading,
		onSearch: setSearch,
	};
};
