import { Box, Divider, Typography } from '@benefitflow/designsystem';
import { useState } from 'react';

export const ClearbitFooter = () => {
	const [hovered, setHovered] = useState(false);

	return (
		<Box width="full" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
			<Divider mb="sm" />
			<Box onClick={() => window.open('https://clearbit.com')} cursor="pointer" onMouseOver={() => setHovered(true)} onMouseOut={() => setHovered(false)}>
				<Typography.Text decoration={hovered ? 'underline' : undefined} size="sm" weight="regular" color="neutral-500">
					Logos provided by Clearbit
				</Typography.Text>
			</Box>
		</Box>
	);
};
