import { useContext, useMemo, useState } from 'react';

import { useEmployersStore } from '../../../Employers.store';
import { LocationUtils } from '../../../../../../../utilities';
import { APIContext } from '../../../../../../../services/api/Api.context';

export const useEmployerLocation = () => {
	const [search, setSearch] = useState('');
	const { filters } = useEmployersStore();
	const {
		employerApi: { useSearchEmployerHq },
	} = useContext(APIContext);
	const {
		data,
		query: { isLoading },
	} = useSearchEmployerHq({ ...filters, employerHqSearch: search });
	const options = useMemo(() => data?.map(LocationUtils.parseLocation) ?? [], [data]);

	return {
		options,
		isLoading,
		onSearch: setSearch,
	};
};
