import { useCallback, useContext } from 'react';

import { useBrokersStore } from '../../../Brokers.store';
import { ExportContext } from '../../../../../../../context/ExportContext';

export const useExportOffices = () => {
	const { filters } = useBrokersStore();
	const { onExport } = useContext(ExportContext);
	const exportOffices = useCallback(() => onExport({ type: 'brokerOffice', filters }), [onExport, filters]);

	return exportOffices;
};
