import { useContext, useRef } from 'react';

import { Header, List } from './components';
import { useBrokersStore } from '../../../Search/Brokers/Brokers.store';
import { useElementDimensions } from '../../../../../hooks/useElementDimensions';
import { TabLayout } from '../../../../shared/TabLayout/TabLayout';
import { Filters } from '../../../Search/Brokers/components';
import { APIContext } from '../../../../../services/api/Api.context';
import { useSearchParams } from 'react-router-dom';

export const Clients = () => {
	const sidebarRef = useRef<HTMLDivElement>(null);
	const { brokerApi } = useContext(APIContext);
	const { filters } = useBrokersStore();
	const [searchParams] = useSearchParams();
	const {
		data,
		query: { isLoading },
	} = brokerApi.useGetBrokerEmployersBreakDown({ ...filters, brokerMasterId: [searchParams.get('brokerId')] });
	const { height: minHeight } = useElementDimensions({ ref: sidebarRef, dependency: filters });

	return (
		<TabLayout
			sidebarRef={sidebarRef}
			buttons={<Header />}
			filters={<Filters isLoading={isLoading} options={data?.breakdowns} />}
			list={<List minHeight={minHeight} />}
		/>
	);
};
