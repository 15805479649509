import { useParams } from 'react-router-dom';
import { useContext, useEffect, useMemo, useState } from 'react';

import { useList } from '../../../../../hooks';
import { addNotification } from '@benefitflow/designsystem';
import { useContactsListStore } from '../../../../../ContactsList.store';
import { ContactsListContext } from '../../../../../ContactsList.context';
import { APIContext } from 'new-beginning/services/api/Api.context';
import { CrmObjectTypeEnum } from 'common.model/src/types/services/integration/fieldMapping/CrmObjectTypeEnum';
import { BrokerContactFilters } from 'common.model/src/types/common/search/filters/ContactFilters';
import { getApiPayloadFromState } from '../../../../../../Search/helpers/getApiPayloadFromState';
import { ListType } from 'common.model/src/db/model-decorators/type-extensions';

export const useMoveSelected = ({ selectedListId, closeDialog }: { selectedListId: string; closeDialog: () => void }) => {
	const { listsApi } = useContext(APIContext);
	const { listId } = useParams();
	const { checkMode, checkedMap } = useContactsListStore();
	const { filters } = useContext(ContactsListContext);
	const list = useList(selectedListId);
	const [itemType, setItemType] = useState<CrmObjectTypeEnum>(null);
	useEffect(() => {
		if (list && list.list_type) setItemType(list.list_type === ListType.BROKER_CONTACT ? CrmObjectTypeEnum.BROKER_CONTACT : CrmObjectTypeEnum.EMPLOYER_CONTACT);
	}, [list]);
	const { checkedMap: contactsCheckedMap, notCheckedMap: contactsNotCheckedMap } = getApiPayloadFromState({ checkedMap, checkMode });
	const { mutate, loading } = listsApi.useMoveListItems(
		{
			contactsCheckedMap,
			contactsNotCheckedMap,
			filters: filters as BrokerContactFilters, // TODO: We should fix this on the backend to be able to support EmployerContactFilters as well!
			fromListId: Number(listId),
			isSelectAll: checkMode === 'select-all',
			itemType,
			toListId: Number(selectedListId),
		},
		{
			onSuccess: (numberOfContactsThatWereMoved) => {
				addNotification({
					type: 'success',
					autoClose: false,
					title: `Moved ${numberOfContactsThatWereMoved} net new contacts to list. `,
					message: `Contacts will appear in ${list?.name ? `"${list?.name}"` : 'the list'} in a few minutes`,
				});
				closeDialog();
			},
			onError: (e) => {
				if (e?.['message']) {
					addNotification({
						type: 'warning',
						autoClose: false,
						message: e?.['message'],
					});
					return;
				}
				addNotification({
					type: 'error',
					autoClose: false,
					title: 'Error moving contacts to list.',
					message: 'Please try again later.',
				});
			},
		},
	);

	return {
		moveSelected: mutate,
		loading,
	};
};
