import { Box } from '@benefitflow/designsystem';

import { Count, ExportButton } from './components';

export const Header = ({ resultsCount, isLoading }: { isLoading: boolean; resultsCount?: number }) => {
	return (
		<Box display="flex" justifyContent="between" alignItems="center">
			<Count isLoading={isLoading} resultsCount={resultsCount} />
			<ExportButton isLoading={isLoading} />
		</Box>
	);
};
