import { useParams } from 'react-router-dom';
import { useCallback, useContext, useMemo } from 'react';
import { addNotification } from '@benefitflow/designsystem';

import { APIContext } from 'new-beginning/services/api/Api.context';
import { useContactsListStore } from '../../../../../ContactsList.store';
import { ContactsListContext } from '../../../../../ContactsList.context';
import { Notifications } from '../../../../../../../../constants/notifications';
import { getApiPayloadFromState } from '../../../../../../Search/helpers/getApiPayloadFromState';
import { BrokerContactFilters } from 'common.model/src/types/common/search/filters/ContactFilters';
import { BulkGetContactResponse } from 'common.model/src/types/common/contact/BulkGetContactResponse';

export const useGetSelected = () => {
	const { listsApi, brokerContactApi } = useContext(APIContext);
	const { listId } = useParams();
	const { filters } = useContext(ContactsListContext);
	const { addPurchasedContact } = useContactsListStore();
	const onError = () => addNotification(Notifications.ContactPurchase.Error);
	const onSuccess = useCallback(
		(data: BulkGetContactResponse) => {
			addPurchasedContact(...data.res.map(({ mobile_phone_1, profile_url, work_email }) => ({ email: work_email, phone: mobile_phone_1, id: profile_url })));
			addNotification(data?.consumption ? Notifications.ContactPurchase.Success(data.consumption) : Notifications.ContactPurchase.NoCharge);
		},
		[addPurchasedContact],
	);
	const { checkMode, checkedMap, tab } = useContactsListStore();
	const mutationFunction = useMemo(() => {
		if (listId) return listsApi.useRevealSelectedListItems;
		if (tab === 'broker') return brokerContactApi.useBulkSelectAllGetContact;
		if (tab === 'employer') throw new Error('Not implemented');
		throw new Error('Invalid tab');
	}, [tab, listId, listsApi, brokerContactApi]);
	const { mutate, isLoading } = mutationFunction({ onError, onSuccess });
	const getSelected = useCallback(() => {
		mutate({
			...getApiPayloadFromState({ checkedMap, checkMode }),
			filters: filters as BrokerContactFilters, // TODO: We should fix this on the backend to be able to support EmployerContactFilters as well!
		});
	}, [mutate, filters, checkedMap, checkMode]);

	return { getSelected, loading: isLoading };
};
