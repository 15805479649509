import { useContext } from 'react';
import { useSearchParams } from 'react-router-dom';

import { BrokerOfficeDTO } from '../../../../../../BrokerProfile.types';
import { APIContext } from '../../../../../../../../../services/api/Api.context';
import { useBrokersStore } from '../../../../../../../Search/Brokers/Brokers.store';
import { BrokerOfficeResult } from 'common.services/src/search/BrokerElasticSearchCache';
import FormattingUtils from '../../../../../../../../../../ui-components/utils/FormattingUtils';

export const useOffices = () => {
	const [searchParams] = useSearchParams();

	const { filters } = useBrokersStore();
	const { brokerApi } = useContext(APIContext);
	const { data, isLoading, isFetched } = brokerApi.useGetBrokerOffices<BrokerOfficeDTO[]>(
		{ ...filters, brokerMasterId: [searchParams.get('brokerId')] },
		{
			select,
		},
	);

	return { data, isLoading, isFetched };
};

const select = (data: BrokerOfficeResult[]): BrokerOfficeDTO[] => {
	return (
		data?.[0]?.offices?.map(({ carrierPremiums, clientCount, commissions, location }) => {
			const name = (() => {
				if (location.city && location.state) return `${FormattingUtils.formatUpperCasing(location.city)}, ${location.state}`;
				return '[Office Not Specified]';
			})();

			return {
				id: encodeURIComponent(name),
				name,
				numberOfClients: `${FormattingUtils.formatCommas(clientCount)} Clients`,
				comissions: `$${FormattingUtils.formatNumberAbbrev(commissions)}`,
				premiums: `$${FormattingUtils.formatNumberAbbrev(carrierPremiums)}`,
				location,
			};
		}) ?? []
	);
};
