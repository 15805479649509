import { useMemo } from 'react';

import { useScreenSize } from '../../../../../hooks/useScreenSize';

export const useHeight = () => {
	const screenSize = useScreenSize();
	const height = useMemo(() => {
		if (screenSize === 'sm') return 280;
		if (screenSize === 'md') return 170;
		return 115;
	}, [screenSize]);

	return height;
};
