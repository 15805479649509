import { create } from 'zustand';
import { StoreUtils } from '../../../../../../utilities';
import { EmployerProfileContactsStore } from './Contacts.types';
import { getDefaultEmployerContactFilters } from 'common.model/src/types/common/search/filters/ContactFilters';

export const FILTERS = ['jobTitles' as const, 'yearsAtCompany' as const, 'roles' as const, 'contactLocation' as const];

export const useEmployerProfileContactsStore = create<EmployerProfileContactsStore>((set) => ({
	...StoreUtils.createFilterVisibilityMethods(FILTERS, set),
	...StoreUtils.createContactSelectorMethods(set),
	filters: getDefaultEmployerContactFilters(),
	clearFilters: () => set({ filters: getDefaultEmployerContactFilters() }),
	setFilters: {
		contactLocation: StoreUtils.createLocationMethods('contactLocation', set),
		jobTitles: StoreUtils.createIncludeExcludeMethods('jobTitles', set),
		roles: StoreUtils.createIncludeExcludeMethods('roles', set),
		yearsAtCompany: StoreUtils.createRangeMethodsLegacy('yearsAtCompany', set),
		companyMasterIds: {
			set: (companyMasterIds: string[]) =>
				set((state) => ({
					filters: { ...state.filters, companyMasterIds },
				})),
		},
	},
}));
