import { useRef } from 'react';

import { Filters, List } from './components';
import { useServiceProviders } from './hooks';
import { TabLayout } from '../../../../shared/TabLayout/TabLayout';
import { SearchBar } from '../SearchBar/SearchBar';
import { useServiceProvidersStore } from './ServiceProviders.store';
import { useElementDimensions } from '../../../../../hooks/useElementDimensions';

export const ServiceProviders = () => {
	const { filters, setFilters } = useServiceProvidersStore();
	const { serviceProviders, fetchNextPage, hasNextPage, isLoading, isFetching } = useServiceProviders();
	const sidebarRef = useRef<HTMLDivElement>(null);
	const { height: minHeight } = useElementDimensions({ ref: sidebarRef, dependency: filters });

	return (
		<TabLayout
			sidebarRef={sidebarRef}
			search={<SearchBar placeholder="Search service providers" value={filters.search} setValue={setFilters.search} />}
			filters={<Filters />}
			list={
				<List
					fetchNextPage={fetchNextPage}
					hasNextPage={hasNextPage}
					minHeight={minHeight}
					isFetching={isFetching}
					serviceProviders={serviceProviders}
					isLoading={isLoading}
				/>
			}
			buttons={<></>}
		/>
	);
};
