import { useCallback, useContext } from 'react';

import { useEmployersStore } from '../../../../../Employers.store';
import { ExportContext } from '../../../../../../../../../context/ExportContext';

export const useExportPolicyDetails = () => {
	const { filters } = useEmployersStore();
	const { onExport } = useContext(ExportContext);
	const exportPolicyDetails = useCallback(() => onExport({ type: 'policy', filters }), [onExport, filters]);

	return exportPolicyDetails;
};
