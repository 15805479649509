import { useMemo } from 'react';
import { Skeleton, Typography } from '@benefitflow/designsystem';
import { FormattingUtils } from '../../../../../../../../ui-components';

export const Count = ({ resultsCount, isLoading }: { resultsCount: number; isLoading: boolean }) => {
	const label = useMemo(() => (resultsCount ? `${FormattingUtils.formatNumberAbbrev(resultsCount)} Broker Results` : 'No Results'), [resultsCount]);
	if (isLoading) return <Skeleton containerBackground="gray" height="md" width="xl6" />;

	return <Typography.Text color="neutral-500">{label}</Typography.Text>;
};
