import { useContext } from 'react';
import { Button } from '@benefitflow/designsystem';
import { useSearchParams } from 'react-router-dom';

import { ExportContext } from '../../../../../../../../context/ExportContext';
import { useBrokersStore } from '../../../../../../Search/Brokers/Brokers.store';
import { EmployerFilters } from 'common.model/src/types/common/search/filters/CompanyFilters';

export const ExportButton = () => {
	const { filters } = useBrokersStore();
	const { onExport, isExporting } = useContext(ExportContext);
	const [searchParams] = useSearchParams();

	return (
		<Button.MultipleOptions
			icon="download"
			placement="bottom-end"
			variant="outlined"
			fetching={isExporting}
			options={[
				{
					label: 'Export Employers',
					onClick: () => {
						onExport({
							type: 'employer',
							filters: {
								...filters,
								brokerMasterId: [searchParams.get('brokerId')],
							} as EmployerFilters,
						});
					},
				},
				{
					label: 'Export Policy Details',
					onClick: () => {
						onExport({
							type: 'policy',
							filters: {
								...filters,
								brokerMasterId: [searchParams.get('brokerId')],
							},
						});
					},
				},
			]}
		>
			Export
		</Button.MultipleOptions>
	);
};
