import { useContext, useEffect, useState } from 'react';
import { useBrokerContactsStore } from '../BrokerContacts.store';
import { BrokerContactsFiltersBreakdownResponse } from 'common.model/src/types/common/search/Breakdowns';
import { APIContext } from 'new-beginning/services/api/Api.context';

export const useFilterOptions = () => {
	const { filters } = useBrokerContactsStore();
	const { brokerContactApi } = useContext(APIContext);
	const { data, loading } = brokerContactApi.useSearchResultsBreakdown(filters);

	const [filterOptions, setFilterOptions] = useState<BrokerContactsFiltersBreakdownResponse>();

	useEffect(() => {
		if (data) setFilterOptions(data);
	}, [data]);

	return { data: filterOptions, loading };
};
