import { useContext, useMemo } from 'react';
import { Button } from '@benefitflow/designsystem';

import { useExportBrokers, useExportOffices } from '../../hooks';
import { ExportContext } from '../../../../../../../../context/ExportContext';

export const ExportButton = ({ isLoading }: { isLoading: boolean }) => {
	const exportBrokers = useExportBrokers();
	const exportOffices = useExportOffices();
	const { isExporting } = useContext(ExportContext);
	const options = useMemo(
		() => [
			{
				label: 'Export Brokers',
				onClick: exportBrokers,
			},
			{
				label: 'Export Brokers Offices',
				onClick: exportOffices,
			},
		],
		[exportBrokers, exportOffices],
	);

	return (
		<Button.MultipleOptions placement="bottom-end" options={options} loading={isLoading} fetching={isExporting} variant="outlined" icon="download">
			Export
		</Button.MultipleOptions>
	);
};
