import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { brokerPageActions } from 'app/containers/BrokerPage/slice';

export const useOnContactsClick = () => {
	const dispatch = useDispatch();

	const onContactsClick = useCallback(() => {
		dispatch(brokerPageActions.updateSearchBy(1));
	}, [dispatch]);

	return onContactsClick;
};
