import { useMemo } from 'react';

import { ListActionUtils } from '../../../../utilities';

export const useNumberOfSelectedContacts = ({
	totalNumberOfContacts,
	checkMode,
	checkedMap,
}: {
	totalNumberOfContacts: number;
	checkMode: 'single-select' | 'select-all';
	checkedMap: Record<string, boolean>;
}) => {
	const numberOfSelectedContacts = useMemo(
		() => ListActionUtils.getNumberOfSelectedContacts({ checkedMap, checkMode, totalNumberOfContacts }),
		[checkMode, checkedMap, totalNumberOfContacts],
	);

	return numberOfSelectedContacts;
};
