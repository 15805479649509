import { useContext } from 'react';

import { ContactDTO } from '../../../../../../../queries';
import { useBrokerContactsDetailsStore } from '../../Contacts.store';
import { APIContext } from '../../../../../../../services/api/Api.context';
import { useNumberOfSelectedContacts } from '../../../../../Search/hooks';
import { ContactListHeader } from '../../../../../../shared/ContactListHeader/ContactListHeader';

export const Header = ({ isLoading, contacts, totalNumberOfContacts }: { contacts: ContactDTO[]; isLoading: boolean; totalNumberOfContacts?: number }) => {
	const store = useBrokerContactsDetailsStore();
	const { brokerContactApi } = useContext(APIContext);
	const numberOfSelectedContacts = useNumberOfSelectedContacts({ checkedMap: store.checkedMap, checkMode: store.checkMode, totalNumberOfContacts });

	return (
		<ContactListHeader context={{ contacts, isLoading, numberOfSelectedContacts, totalNumberOfContacts }} store={store} type="broker">
			<ContactListHeader.GetSelectButton useMutation={brokerContactApi.useBulkSelectAllGetContact} />
			<ContactListHeader.ExportButton />
			<ContactListHeader.AddToListButton
				useAddToExistingListMutation={brokerContactApi.useAddSelectedToExistingList}
				useAddToNewListMutation={brokerContactApi.useAddSelectedToNewList}
			/>
		</ContactListHeader>
	);
};
