import { LocationSelection } from 'common.model/src/types/master/location';
import { IncludeExcludeField, Range } from './filters';

export enum BrokerOfficeTypeEnum {
	ReportedBrokerOffice = 'Reported Broker Office',
	ClosestBrokerOffice = 'Closest Broker Office',
}

export interface EmployerFilters {
	employerNameSearch: string;
	employerMasterId: string[];
	employerHQLocations: IncludeExcludeField<LocationSelection[]>;
	entityTypes: IncludeExcludeField<string[]>;
	planTypes: IncludeExcludeField<string[]>;
	lineOfBusiness: IncludeExcludeField<string[]>;
	participantRanges: Range;
	renewalDatesRanges: Range;
	industries: IncludeExcludeField<string[]>;
	carrierName: IncludeExcludeField<string[]>;
	brokerSize: IncludeExcludeField<string[]>;
	brokerName: IncludeExcludeField<string[]>;
	primaryBrokerOfficeLocations: IncludeExcludeField<LocationSelection[]>;
	page: number;
	groupBy: BrokerOfficeTypeEnum;
	employerPEOName: IncludeExcludeField<string[]>;
	employerAccountantFirmName: IncludeExcludeField<string[]>;
}

export interface BrokerFilters extends EmployerFilters {
	brokerMasterId: string[];
	masterBrokerNameSearch: string;
	filterPrimaryBroker: boolean;
	brokerOfficeLocations: IncludeExcludeField<LocationSelection[]>;
}

export interface ProviderFilters {
	states: string[];
	search: string;
}

// Create an empty object for filters BrokerFilters
let getEmptyEmployerFilters = (): EmployerFilters => ({
	employerNameSearch: '',
	employerMasterId: [],
	employerHQLocations: { include: [], exclude: [] },
	entityTypes: { include: [], exclude: [] },
	planTypes: { include: [], exclude: [] },
	lineOfBusiness: { include: [], exclude: [] },
	participantRanges: { start: 0, end: 3000000 },
	renewalDatesRanges: { start: 1, end: 12 },
	industries: { include: [], exclude: [] },
	carrierName: { include: [], exclude: [] },
	brokerSize: { include: [], exclude: [] },
	brokerName: { include: [], exclude: [] },
	primaryBrokerOfficeLocations: { include: [], exclude: [] },
	employerPEOName: { include: [], exclude: [] },
	employerAccountantFirmName: { include: [], exclude: [] },
	page: 0,
	groupBy: BrokerOfficeTypeEnum.ClosestBrokerOffice,
});

let getEmptyBrokerFilters = (): BrokerFilters => ({
	...getEmptyEmployerFilters(),
	brokerOfficeLocations: { include: [], exclude: [] },
	brokerMasterId: [],
	masterBrokerNameSearch: '',
	filterPrimaryBroker: false,
});

let getEmptyProviderFilters = (): ProviderFilters => ({
	states: [],
	search: '',
});

export { getEmptyEmployerFilters, getEmptyBrokerFilters, getEmptyProviderFilters };
