import { useParams } from 'react-router-dom';

import { List, Header } from './components';
import { useContactsQuery } from '../../../queries';
import { useContacts } from '../../../hooks/useContacts';
import { useContactsListStore } from './ContactsList.store';
import { ContactsListContext } from './ContactsList.context';
import { useNumberOfSelectedContacts, useSidebarDimensions, useSyncWithExternalTabStore } from './hooks';
import { useGetList } from 'new-beginning/hooks/lists/useGetList';
import { ListType } from 'common.model/src/db/model-decorators/type-extensions';
import { ContactTypeEnum } from 'common.model/src/types/ContactTypeEnum';
import { Box, Tabs } from '@benefitflow/designsystem';
import { useEffect, useState, useMemo } from 'react';

export const ContactsList = ({
	filters,
	totalNumberOfContacts,
	isLoadingFilters,
	setExternalTabStore,
	sidebarRef,
}: {
	filters;
	totalNumberOfContacts: number;
	isLoadingFilters: boolean;
	setExternalTabStore: (tab: number) => void;
	sidebarRef: React.MutableRefObject<HTMLDivElement>;
}) => {
	const { listId } = useParams();
	const store = useContactsListStore();
	const { data: allLists } = useGetList();
	const fetchedListType = useMemo(() => {
		const list = allLists?.find((l) => l.id === Number(listId));
		return list?.listType;
	}, [listId, allLists]);
	const [selectedListType, setSelectedListType] = useState<ListType>(fetchedListType ?? listId === '-1' ? ListType.BROKER_CONTACT : null);
	const [contactType, setContactType] = useState<ContactTypeEnum>(null);
	useEffect(() => {
		if (fetchedListType) setSelectedListType(fetchedListType);
	}, [fetchedListType, setSelectedListType]);
	useEffect(() => {
		if (selectedListType) setContactType(selectedListType === ListType.BROKER_CONTACT ? ContactTypeEnum.BROKER : ContactTypeEnum.EMPLOYER);
	}, [selectedListType, setContactType]);
	useEffect(() => {
		store.setState({ checkedMap: {}, checkMode: 'single-select' });
	}, [contactType])
	const { data, hasNextPage, isLoading, isFetching, fetchNextPage } = useContactsQuery({
		contactType,
		filters: {
			...filters,
			listIds: [listId], // Hack: the listId isnt set in the filters yet because in redux so adding this so that it doesnt flicker with with contact search without the list id!
		},
		options: {
			getNextPageParam: (lastPage) => {
				if (!lastPage?.anonymizedContacts?.length) return null;
				return lastPage.page + 1;
			},
			enabled: !!listId && !!contactType,
		},
	});
	const contacts = useContacts({ data, store });
	const numberOfSelectedContacts = useNumberOfSelectedContacts({ totalNumberOfContacts });
	const { height: sidebarHeight } = useSidebarDimensions(sidebarRef);
	useSyncWithExternalTabStore({ setExternalTabStore, listType: selectedListType });

	return (
		<ContactsListContext.Provider
			value={{
				filters,
				isLoadingFilters,
				listType: selectedListType,
				contacts,
				isFetching,
				isLoading,
				fetchNextPage,
				hasNextPage,
				totalNumberOfContacts,
				numberOfSelectedContacts,
			}}
		>
			{listId === '-1' && (
				<Box display="flex" flexDirection="column" mb="sm">
					<Tabs align="start" value={selectedListType} onChange={(newListType) => setSelectedListType(newListType as ListType)}>
						<Tabs.Tab value={ListType.BROKER_CONTACT} label="Broker Contacts" />
						<Tabs.Divider />
						<Tabs.Tab value={ListType.EMPLOYER_CONTACT} label="Employer Contacts" />
					</Tabs>
				</Box>
			)}
			<Header />
			<List minHeight={sidebarHeight} />
		</ContactsListContext.Provider>
	);
};
