import { AutoComplete, Collapsible, InputLabel, Tag } from '@benefitflow/designsystem';
import { FiltersProps } from '../../Filters.types';
import { useMemo, useState } from 'react';
import { useOptions } from './hooks';

export interface StringSearchFilterProps extends FiltersProps {
	value: string;
	onSelect: (value: string) => void;
	onDelete: (value: string) => void;
}

export const StringSearchFilter = ({ title, onSelect, value, description, icon, isOpen, onDelete, onToggle, placeholder }: StringSearchFilterProps) => {
	const [search, onSearch] = useState('');
	const options = useOptions({ search });
	const selectedValues = useMemo(() => (value ? [value] : []), [value]);

	return (
		<Collapsible
			headerTestId="carrier-appointments-filter-header"
			isOpen={isOpen}
			toggle={onToggle}
			title={title}
			icon={icon}
			content={value && !isOpen && <Tag onDelete={() => onDelete(value)} label={value} variant="primary" />}
			openContent={
				<InputLabel label={description}>
					<AutoComplete
						onClear={() => onDelete(value)}
						mode="single"
						inputTestId="job-title-filter-input"
						selectedValues={selectedValues}
						loading={false}
						placeholder={placeholder}
						onSelect={({ value }) => onSelect(value)}
						options={options}
						onSearch={onSearch}
					/>
				</InputLabel>
			}
		/>
	);
};
