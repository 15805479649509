import { useContext, useMemo, useState } from 'react';

import { useBrokersStore } from '../../../Brokers.store';
import { APIContext } from '../../../../../../../services/api/Api.context';
import { LocationUtils } from '../../../../../../../utilities';

export const useEmployerHeadquarters = () => {
	const [search, setSearch] = useState('');
	const { filters } = useBrokersStore();
	const { brokerApi } = useContext(APIContext);
	const {
		data,
		query: { isLoading },
	} = brokerApi.useSearchEmployerHq({ ...filters, employerHqSearch: search, page: 0 });
	const options = useMemo(() => data?.map(LocationUtils.parseLocation) ?? [], [data]);

	return { options, isLoading, onSearch: setSearch };
};
