import {
	GetFeesPaidToServiceProvidersRequest,
	GetPlanPaymentsToProviderRequest,
	IFeesPaidToServiceProvider,
	ProviderSearchRequest,
	ProviderSearchResponse,
} from 'common.model/src/types/services/provider-service/ProviderService';
import { ApiBase, IApiResponse } from '../ApiBase';
import { APIClient } from '../types';

export interface IProviderApi {
	useSearch: (body: ProviderSearchRequest) => Promise<ProviderSearchResponse>;
	useSearchResultsBreakdown: (body: ProviderSearchRequest) => IApiResponse<number>;
	useGetFeesPaidToServiceProviders: (body: GetFeesPaidToServiceProvidersRequest) => IApiResponse<IFeesPaidToServiceProvider[]>;
	useGetPlanPaymentsToProvider: (body: GetPlanPaymentsToProviderRequest) => IApiResponse<string>;
}

export class ProviderApi extends ApiBase implements IProviderApi {
	constructor(
		readonly client: APIClient,
		loggedInUser: string,
	) {
		super(client, loggedInUser, 'provider');
	}

	useSearch = (body: ProviderSearchRequest): Promise<ProviderSearchResponse> => this.post('search', body);

	useSearchResultsBreakdown = (body: ProviderSearchRequest): IApiResponse<number> => this.callAuthenticated('searchResultsBreakdown', body);

	useGetFeesPaidToServiceProviders = (body: GetFeesPaidToServiceProvidersRequest): IApiResponse<IFeesPaidToServiceProvider[]> =>
		this.callAuthenticated('getFeesPaidToServiceProviders', body);

	useGetPlanPaymentsToProvider = (body: GetPlanPaymentsToProviderRequest): IApiResponse<string> => this.callAuthenticated('getPlanPaymentsToProvider', body);
}
