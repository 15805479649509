import { useContext } from 'react';

import { ContactDTO } from '../../../../../../queries';
import { useBrokerContactsStore } from '../../BrokerContacts.store';
import { APIContext } from '../../../../../../services/api/Api.context';
import { ContactListHeader } from '../../../../../shared/ContactListHeader/ContactListHeader';

export const Header = ({
	contacts,
	isLoading,
	numberOfSelectedContacts,
	totalNumberOfContacts,
}: {
	contacts: ContactDTO[];
	isLoading: boolean;
	numberOfSelectedContacts: number;
	totalNumberOfContacts: number;
}) => {
	const store = useBrokerContactsStore();
	const { brokerContactApi } = useContext(APIContext);

	return (
		<ContactListHeader context={{ contacts, isLoading, numberOfSelectedContacts, totalNumberOfContacts }} store={store} type="broker">
			<ContactListHeader.GetSelectButton useMutation={brokerContactApi.useBulkSelectAllGetContact} />
			<ContactListHeader.ExportButton />
			<ContactListHeader.AddToListButton
				useAddToExistingListMutation={brokerContactApi.useAddSelectedToExistingList}
				useAddToNewListMutation={brokerContactApi.useAddSelectedToNewList}
			/>
		</ContactListHeader>
	);
};
