import { Box, Button, Icon, Skeleton, Suspense, Typography } from '@benefitflow/designsystem';
import { useOnClickBack } from './hooks';
import { useGetValueByScreenSizes } from 'new-beginning/hooks/useGetValueByScreenSizes';
import { useSummary } from '../../hooks';

export const Header = () => {
	const getValuesByScreenSize = useGetValueByScreenSizes();
	const { loading, name } = useSummary();
	const onClickBack = useOnClickBack();

	return (
		<Box
			style={{ borderBottom: '0.5px solid #CBCDD1' }}
			p="md"
			backgroundColor="white"
			display="flex"
			flexDirection={getValuesByScreenSize({ lg: 'row', md: 'row', sm: 'column' })}
			width="full"
			justifyContent="between"
			gap="md"
		>
			<Box display="flex" alignItems="center" gap="sm">
				<Button loading={loading} variant="outlined" onClick={onClickBack}>
					<Icon name="arrow-left" size="md" />
				</Button>
				<Suspense loading={loading} fallback={<Skeleton height="sm" width="xl6" />}>
					<Box display="flex" gap="sm" justifyContent="center" alignItems="center">
						<Typography.Text>Broker Search</Typography.Text>
						<Typography.Text>•</Typography.Text>
						<Typography.Text>{name}</Typography.Text>
					</Box>
				</Suspense>
			</Box>
		</Box>
	);
};
